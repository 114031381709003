import { sumBy } from "lodash";

import { Fragment, useCallback, useMemo, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { useAssistance } from "../../RootNavigator/services/Assistance";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import ProjectReport from "./ProjectReport";
import Button from "@hpo/client/components/Button";
import Dialog from "@hpo/client/components/Dialog";
import Toasting from "@hpo/client/components/Toasting";
import {
  InvestigationCreation,
  Notation,
  NotationCreation,
} from "@hpo/client/models/Investigation";
import { ProjectInstructor } from "@hpo/client/models/Project";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import InvestigationDecision, {
  InvestigationDecisionLabels,
  InvestigationDecisionValues,
} from "@hpo/client/utilities/enums/InvestigationDecision";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import DropDownField from "@hpo/client/utilities/fields/DropDownField";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { canProjectHaveInquiry } from "@hpo/client/utilities/helpers/ProjectHelper";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { PreviewView } from "@hpo/client/utilities/Preview";
import T from "@hpo/client/components/text/Text";
import List, { ListItem } from "@hpo/client/utilities/List";
import Columns from "@hpo/client/utilities/Columns";
import PrintPreviewModal from "@hpo/client/utilities/print/PrintPreviewModal";

type ProjectTabInvestigationProps = {
  project: ProjectInstructor;
  onReload: () => unknown;
};

type InvestigationData = {
  comment: string | null;
  decision: InvestigationDecision | null;
};

export default function ProjectTabInvestigation(
  props: ProjectTabInvestigationProps,
) {
  const server = useServerSdk();
  const { project, onReload } = props;

  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);

  const [notations, setNotations] = useState<Notation[]>(
    () => project.notations,
  );

  const amount = useMemo(() => {
    return project.expenses.reduce((acc, expense) => {
      return acc + (expense.proposal || 0);
    }, 0);
  }, [project.expenses]);

  const [investigation, setInvestigation] = useState<InvestigationData>(() => {
    return (
      project.investigation || {
        comment: null,
        decision: null,
      }
    );
  });

  const readOnly = !canProjectHaveInquiry(project);

  const [submitInstruction, submission] = useSubmitCallback(async () => {
    const notationsCreation: NotationCreation[] = [];

    const allNotationsHaveValue = notations.reduce(
      (allNotationsHaveValue, notation) => {
        if (notation.value === null) return false;
        else {
          notationsCreation.push({
            key: notation.key,
            value: notation.value,
          });
          return allNotationsHaveValue;
        }
      },
      true,
    );

    const allExpensesHaveAmountProposed = project.expenses.reduce(
      (allExpensesHaveAmountProposed, expense) => {
        if (expense.proposal === null) return false;
        else return allExpensesHaveAmountProposed;
      },
      true,
    );

    if (!allNotationsHaveValue) {
      throw new MessageException(
        "Tous les critères doivent avoir une note de saisie pour valider l'instruction",
        null,
      );
      return;
    }

    if (!allExpensesHaveAmountProposed) {
      throw new MessageException(
        "Le montant d'aide proposé doit être renseigné pour toutes les actions.",
        null,
      );
      return;
    }

    if (investigation.comment === null) {
      throw new MessageException(
        "Le commentaire d'instruction doit être renseigné.",
        null,
      );
      return;
    }

    if (investigation.decision === null) {
      throw new MessageException(
        "La décision d'instruction doit être renseignée.",
        null,
      );
    }

    const investigationCreation: InvestigationCreation = {
      amount,
      comment: investigation.comment,
      decision: investigation.decision,
      notations: notationsCreation,
    };

    const confirm = await Dialog.confirm({
      message:
        "Souhaitez-vous vraiment confirmer l'instruction de ce dossier ? Cette action est définitive.",
    });

    if (!confirm) return;
    await server.createInvestigation(project.id, investigationCreation);
    onReload();
    Toasting.success("Succès", "L'instruction a été correctement finalisée");
  }, [notations, investigation]);

  const onNotationValueChange = useCallback(
    (value: number | null, notation: Notation) => {
      setNotations((oldNotations: Notation[]) =>
        oldNotations.map((oNotation) => {
          const editedNotation: Notation =
            oNotation.id !== notation.id
              ? oNotation
              : {
                  ...oNotation,
                  value: value,
                };

          return editedNotation;
        }),
      );
    },
    [],
  );

  const onCommentChange = (value: string | null) => {
    setInvestigation((oldInvestigation) => {
      return {
        ...oldInvestigation,
        comment: value,
      };
    });
  };

  const onDecisionChange = (value: InvestigationDecision | null) => {
    setInvestigation((oldInvestigation) => {
      return {
        ...oldInvestigation,
        decision: value,
      };
    });
  };

  const totalAmount = useMemo(() => {
    return notations.reduce((sum, notation) => {
      return sum + (notation.value || 0);
    }, 0);
  }, [notations]);

  const [createProjectReport] = useSubmitCallback(async () => {
    await server.createProjectReport(project.id);
    onReload();
  }, [project]);

  const [preview, setPreview] = useState(false);

  return (
    <Fragment>
      <T style="section">Notation</T>
      <List
        data={notations}
        renderItem={(notation) => (
          <ListItem
            label={notation.criteria}
            help={notation.description}
            right={
              <div css={{ width: 100, flexShrink: 0 }}>
                <NumberField
                  required
                  value={notation.value}
                  onChange={(value) => onNotationValueChange(value, notation)}
                  placeholder={`${notation.maximum} max`}
                  min={0}
                  max={notation.maximum}
                  help={`Note sur ${notation.maximum}`}
                  disabled={readOnly}
                  allowZero
                />
              </div>
            }
          />
        )}
      />
      <Spacer />
      <T style="section">Conclusion</T>
      <Columns columns={3}>
        <DropDownField
          label="Décision"
          required
          options={[...InvestigationDecisionValues]}
          keyExtractor={(k) => (!!k).toString()}
          renderOption={(d) => ({ label: InvestigationDecisionLabels[d] })}
          onChange={onDecisionChange}
          value={investigation.decision}
          readonly={readOnly}
        />
        <NumberField
          required
          value={amount}
          allowZero
          label="Montant proposé"
          // onChange={(value) => onMontantChange(value)}
          unit="euro"
          css={{ width: "100%" }}
          readonly={true}
        />
        <NumberField
          required
          value={totalAmount}
          allowZero
          label="Note"
          css={{ width: "100%" }}
          help={`Note sur ${sumBy(notations, (n) => n.maximum)}`}
          readonly={true}
        />
      </Columns>
      <TextField
        label="Commentaire"
        required
        textArea={true}
        value={investigation.comment}
        onChange={onCommentChange}
        readonly={readOnly}
      />
      <Spacer size={4} />
      <Button
        label="Valider l'instruction"
        onClick={submitInstruction}
        disabled={readOnly}
      />
      <Spacer />
      <T style="section">Rapport d'instruction</T>
      <Spacer />
      {readOnly ? (
        <Fragment>
          <Spacer />
          {project.report ? (
            <Fragment>
              <PreviewView file={project.report} size={600} />
              <Spacer />
            </Fragment>
          ) : null}
          <Button
            label="Rediger le rapport d'instruction"
            onClick={createProjectReport}
            disabled={!readOnly}
          />
        </Fragment>
      ) : (
        <Fragment>
          <PrintPreviewModal
            onClose={() => setPreview(false)}
            visible={preview}
            width="29.7cm"
            margin="1cm"
          >
            <ProjectReport project={project} appUrl={location.origin} />
          </PrintPreviewModal>
          <WithHelp text="Validez l'instruction pour que le rapport soit définitivement rédigé">
            <Button
              label="Prévisualiser le rapport d'instruction"
              onClick={() => setPreview(true)}
            />
          </WithHelp>
        </Fragment>
      )}
      <Spacer />

      <ErrorToast error={submission.error} />
    </Fragment>
  );
}
