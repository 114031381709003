import { css } from "@emotion/react";
import { PropsWithChildren, ReactNode } from "react";
import { rgba } from "polished";
import Theme from "../utilities/Theme/client";
import T from "./text/Text";

type MessageSeverity = "info" | "success" | "warn" | "error";

type MessageZoneProps = PropsWithChildren<{
  /** @deprecated : Prefer color */
  severity?: "info" | "success" | "warn" | "error";
  color?: string;
  /** @deprecated : Prefer children */
  content?: ReactNode;
}>;

export default function MessageZone(props: MessageZoneProps) {
  const color = props.severity
    ? colors[props.severity]
    : props.color
      ? props.color
      : Theme.color;
  const containerCss = css({
    background: rgba(color, 0.1),
    color: color,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    overflow: "hidden",
  });

  const lineCss = css({
    height: 2,
    background: rgba(color, 1),
  });

  const contentCss = css({
    padding: 10,
  });

  let children: ReactNode = props.children || props.content;
  if (typeof children === "string") children = <T>{children}</T>;

  return (
    <div css={containerCss}>
      <div css={lineCss} />
      <div css={contentCss}>{children}</div>
    </div>
  );
}

const colors: Record<MessageSeverity, string> = {
  info: Theme.color,
  success: Theme.color,
  warn: Theme.warning,
  error: Theme.error,
};
