import { css } from "@emotion/react";
import dayjs from "dayjs";
import { Fragment, useEffect, useMemo, useRef } from "react";
import ChatMessage from "../ChatMessage";
import ChatTextInput from "./ChatTextInput";
import Intersperse from "@hpo/client/components/Intersperse";
import { Message } from "@hpo/client/models/Message";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import Spacer from "@hpo/client/utilities/Spacer";
import Theme from "@hpo/client/utilities/Theme/client";
import T from "@hpo/client/components/text/Text";
import AuthorRole from "@hpo/client/utilities/enums/AuthorRole";
import MessageZone from "@hpo/client/components/Message";

type ChatMessagesProps = {
  target: OrganizationSummary | true;
  messages: Message[];
  onMessageSubmit: (text: string) => unknown;
  reload: () => void;
};

export default function ChatMessages({
  target,
  messages,
  onMessageSubmit,
}: ChatMessagesProps) {
  const messagesLength = useMemo(() => {
    return messages.length;
  }, [messages]);

  const messagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!messagesRef.current) return;
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  }, [messagesLength]);

  const sortedMessages = useMemo(() => {
    return messages.sort((a, b) => {
      const dateA = dayjs(a.date);
      const dateB = dayjs(b.date);

      return dateA.diff(dateB);
    });
  }, [messages]);

  const contentCss = css({
    padding: 20,
    borderRadius: 6,
    backgroundColor: "white",
    border: "1px solid",
    borderColor: Theme.color,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  });

  const messagesCss = css({
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    flexDirection: "column",
    scroll: "auto",
    overflow: "auto",
  });

  return (
    <div css={contentCss}>
      <T style="subtitle">
        {target === true ? "Poser une question" : `${target.businessName}`}
      </T>
      <Spacer />
      {target === true ? (
        <Fragment>
          <MessageZone color={Theme.warning}>
            Merci de bien vouloir utiliser cet outil chat de façon modérée et de
            poser des questions aussi formelles qu’un mail officiel au service
            instructeur
          </MessageZone>
          <Spacer />
        </Fragment>
      ) : null}
      <div css={messagesCss} ref={messagesRef}>
        <Intersperse between={() => <Spacer />}>
          {sortedMessages.map((message) => {
            return (
              <ChatMessage
                message={message}
                key={message.id}
                currentRole={
                  target === true
                    ? AuthorRole.Instructor
                    : AuthorRole.Beneficiary
                }
              />
            );
          })}
        </Intersperse>
      </div>
      <Spacer />
      <ChatTextInput onSend={onMessageSubmit} />
    </div>
  );
}
