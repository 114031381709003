import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, ReactNode } from "react";
import { renderToString } from "react-dom/server";
import Theme from "../Theme/client";
import { getPrintHash, getPrintHtml } from "./utilities";
import Button from "@hpo/client/components/Button";
import T from "@hpo/client/components/text/Text";

type PrintPreviewModalProps = {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
  width: string;
  margin: string;
};

export default function PrintPreviewModal(props: PrintPreviewModalProps) {
  const { visible, children, onClose, width, margin } = props;

  const modalCss = css({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: rgba("white", 0.1),
    opacity: visible ? 1 : 0,
    transition: "all 300ms",
    zIndex: 1000,
    pointerEvents: visible ? "auto" : "none",
    backdropFilter: `blur(2px)`,
  });

  const containerCss = css({
    position: "absolute",
    width: width,
    top: 100,
    bottom: 100,
    left: "50vw",
    transform: "translateX(-50%)",
    opacity: visible ? 1 : 0,
    transition: "all 300ms",
    zIndex: 1001,
    background: "white",
    boxShadow: `0px 0px 5vw ${rgba("black", 0.5)}`,
    overflow: "auto",
  });

  const headerCss = css({
    position: "absolute",
    top: 10,
    right: 10,
  });

  const hashCss = css({
    position: "absolute",
    bottom: 10,
    left: "50%",
    transform: "translateX(-50%)",
  });

  const iframeCss = css({
    border: "none",
    width: "100%",
    height: "100%",
    padding: margin,
    display: visible ? "block" : "none",
  });

  const html = getPrintHtml(window.origin, renderToString(children), Theme);
  const hash = getPrintHash(html);

  return (
    <Fragment>
      <div css={modalCss}>
        <div css={headerCss}>
          <Button
            icon={{ name: "close", rotate: 90 }}
            style="discreet"
            onClick={onClose}
          />
        </div>
        <div css={containerCss}>
          <iframe srcDoc={html} css={iframeCss}></iframe>
        </div>
        {hash !== null ? (
          <div css={hashCss}>
            <T style="minor">Signature du contenu : {hash}</T>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}
