const Config = {
  server: {
    url: import.meta.env.VITE_SERVER_URL,
  },
  logtail_source_token: import.meta.env.VITE_LOGTAIL_SOURCE_TOKEN,
  build_date: import.meta.env.VITE_BUILD_DATE,
  color: import.meta.env.VITE_THEME_COLOR,
};

export default Config;
