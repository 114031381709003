import { useState } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import { SalaryBeneficiary } from "@hpo/client/models/Expense";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { ProjectDraft } from "@hpo/client/models/Project";
import Toasting from "@hpo/client/components/Toasting";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import ExpenseType from "@hpo/client/utilities/enums/ExpenseType";

type SalarySideBarProps = {
  project: ProjectDraft;
  salary: SalaryBeneficiary | null;
  visible: boolean;
  onDone: (newSalary: string | null) => unknown;
};

/** @todo : voir navigators/MyProjectsNavigator/MyProjectScreen/MyProjectActions/ActionSideBar.tsx */
export default function SalarySideBar(props: SalarySideBarProps) {
  const { project, salary, visible, onDone } = props;
  const server = useServerSdk();

  const [label, setLabel] = useState<string | null>(salary && salary.label);

  const [employeeName, setEmployeeName] = useState<string | null>(
    salary && salary.employeeName,
  );

  const [employeePosition, setEmployeePosition] = useState<string | null>(
    salary && salary.employeePosition,
  );

  const [affectation, setAffectation] = useState<number | null>(
    salary && salary.affectation,
  );

  const [annualCost, setAnnualCost] = useState<number | null>(
    salary && salary.annualCost,
  );

  const [saveSalary, savingStatus] = useSubmitCallback(async () => {
    if (
      !label ||
      !employeeName ||
      !employeePosition ||
      !affectation ||
      annualCost === null
    ) {
      throw new MessageException("Veuillez remplir tous les champs", null);
    }

    if (salary) {
      await server.updateExpense(project.id, salary.id, {
        label,
        type: ExpenseType.Salary,
        employeeName,
        employeePosition,
        affectation,
        annualCost,
      });
      Toasting.success("Succès", "Dépense mise à jour");
      onDone(salary.id);
    } else {
      const expense = await server.createExpense(project.id, {
        label,
        type: ExpenseType.Salary,
        employeeName,
        employeePosition,
        affectation,
        annualCost,
      });
      Toasting.success("Succès", "Dépense créée");
      onDone(expense);
    }
  }, [label, employeeName, employeePosition, affectation, annualCost, salary]);

  return (
    <SideBar
      visible={visible}
      onHide={() => onDone(null)}
      header={
        <TextTitleMinor>
          {salary ? salary.label : "Nouveau salaire"}
        </TextTitleMinor>
      }
    >
      <ErrorToast error={savingStatus.error} />
      <TextField
        required
        id="label"
        label="Intitulé"
        value={label}
        onChange={setLabel}
      />
      <Spacer />
      <TextField
        required
        id="employeeName"
        label="Nom"
        value={employeeName}
        onChange={setEmployeeName}
      />
      <Spacer />
      <TextField
        required
        id="employeePosition"
        label="Fonction"
        value={employeePosition}
        onChange={setEmployeePosition}
      />
      <Spacer />
      <NumberField
        id="annualCost"
        value={annualCost}
        onChange={setAnnualCost}
        label="Coût annuel"
        unit="euro"
        allowZero
      />
      <Spacer />
      <NumberField
        id="affectation"
        value={affectation}
        onChange={setAffectation}
        label="Affectation"
        unit="percentage"
        allowZero
      />
      <Spacer scale={2} />
      <Button
        onClick={saveSalary}
        label="Valider"
        loading={savingStatus.running}
      />
    </SideBar>
  );
}
