import Badge from "./Badge";
import { InstructorPaymentStatus } from "./enums/InstructorPaymentStatus";
import Theme from "./Theme/client";

type InstructorPaymentStatusBadgeProps = {
  status: InstructorPaymentStatus;
};

export default function InstructorPaymentStatusBadge(
  props: InstructorPaymentStatusBadgeProps,
) {
  const { status } = props;
  const label = InstructorPaymentStatusLabels[status];
  const color = InstructorPaymentStatusColors[status];
  return <Badge color={color}>{label}</Badge>;
}

export const InstructorPaymentStatusLabels: Record<
  InstructorPaymentStatus,
  string
> = {
  [InstructorPaymentStatus.Configurable]: "Planifié",
  [InstructorPaymentStatus.WaitingForDeclaration]: "Soumis au bénéficiaire",
  [InstructorPaymentStatus.UnderInstruction]: "Instruction à réaliser",
  [InstructorPaymentStatus.UnderCertification]: "Validation niveau 1 à donner",
  [InstructorPaymentStatus.UnderValidation]: "Validation niveau 2 à donner",
  [InstructorPaymentStatus.Paid]: "Clos",
};

export const InstructorPaymentStatusColors: Record<
  InstructorPaymentStatus,
  string
> = {
  [InstructorPaymentStatus.Configurable]: "grey",
  [InstructorPaymentStatus.WaitingForDeclaration]: Theme.color,
  [InstructorPaymentStatus.UnderInstruction]: Theme.warning,
  [InstructorPaymentStatus.UnderCertification]: Theme.warning,
  [InstructorPaymentStatus.UnderValidation]: Theme.warning,
  [InstructorPaymentStatus.Paid]: "grey",
};
