import { Route, Switch } from "wouter";
import MyPaymentsNavigator from "../MyPaymentsNavigator";
import ProjectNavigator from "../ProjectNavigator";
import FourOFour from "../RootNavigator/FourOFour";
import ManagementNavigator from "../ManagementNavigator";
import ConventionsNavigator from "../ConventionsNavigator";
import PaymentsNavigator from "../PaymentsNavigator";
import MyProjectsNavigator from "../MyProjectsNavigator";
import YearlyReportNavigator from "../YearlyReportNavigator";
import WelcomeScreen from "./WelcomeScreen";
import {
  conventionsRoute,
  homeRoute,
  myPaymentsRoute,
  myProjectsRoute,
  paymentsRoute,
  projectsRoute,
  managementRoute,
  errorRoute,
  yearlyReportRoute,
} from "@hpo/client/utilities/routes";
import messageExceptionCaster from "@hpo/client/message-exception-caster";
import MessageException from "@hpo/client/utilities/errors/MessageException";

export default function LoggedInNavigator() {
  return (
    <Switch>
      <Route path={homeRoute.getPath()} component={WelcomeScreen} />
      <Route path={projectsRoute.getPath()} component={ProjectNavigator} nest />
      <Route
        path={conventionsRoute.getPath()}
        component={ConventionsNavigator}
        nest
      />
      <Route
        path={myProjectsRoute.getPath()}
        component={MyProjectsNavigator}
        nest
      />
      <Route
        path={myPaymentsRoute.getPath()}
        component={MyPaymentsNavigator}
        nest
      />
      <Route
        path={paymentsRoute.getPath()}
        component={PaymentsNavigator}
        nest
      />
      <Route
        path={managementRoute.getPath()}
        component={ManagementNavigator}
        nest
      />
      <Route
        path={yearlyReportRoute.getPath()}
        component={YearlyReportNavigator}
        nest
      />
      <Route path={errorRoute.getPath()} component={TestErrorView} nest />
      <Route component={FourOFour} />
    </Switch>
  );
}

function TestErrorView() {
  throw new TestError();
  return null;
}

class TestError extends Error {}

messageExceptionCaster.registerClass(
  TestError,
  () => new MessageException("Ceci est une erreur de test", null),
);
