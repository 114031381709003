import {
  Infer,
  array,
  enums,
  nullable,
  number,
  object,
  optional,
  size,
  string,
} from "superstruct";
import { phoneCoercer } from "../utilities/helpers/ProjectHelper";
import { ProjectStatusSchema } from "../utilities/enums/ProjectStatus";
import AnnexType from "../utilities/enums/AnnexType";
import { ActionSchema } from "./Action";
import { AnalysisSchema } from "./Analysis";
import {
  AttachmentBeneficiarySchema,
  AttachmentInstructorSchema,
} from "./Attachment";
import {
  CommitmentBeneficiarySchema,
  CommitmentInstructorSchema,
} from "./Commitment";
import {
  ComplianceBeneficiarySchema,
  ComplianceInstructorSchema,
} from "./Compliance";
import {
  DescriptionBeneficiarySchema,
  DescriptionInstructorSchema,
} from "./Description";
import { ExpenseBeneficiarySchema, ExpenseInstructorSchema } from "./Expense";
import { OrganizationSchema } from "./Organization";
import { UserSchema } from "./User";
import { InvestigationSchema, NotationSchema } from "./Investigation";
import { ProposalSchema } from "./Proposal";
import { SignatureSchema } from "./Signature";
import {
  BeneficiaryIndicatorSchema,
  InstructorIndicatorSchema,
} from "./Indicator";
import ProgramKey, {
  ProgramKeySchema,
} from "@hpo/client/utilities/enums/ProgramKey";

// Annex

export const AnnexBeneficiarySchema = object({
  resource: string(),
  type: enums(Object.values(AnnexType)),
});

export type AnnexBeneficiary = Infer<typeof AnnexBeneficiarySchema>;

export const AnnexSchema = object({
  resource: string(),
  type: enums(Object.values(AnnexType)),
  analysis: AnalysisSchema,
});

export type Annex = Infer<typeof AnnexSchema>;

// Project
export const ProjectDraftSchema = object({
  id: string(),
  type: ProgramKeySchema,
  label: string(),
  owner: UserSchema,
  organization: OrganizationSchema,
  contactName: nullable(string()),
  contactPhoneNumber: nullable(phoneCoercer),
  contactMobilePhoneNumber: nullable(phoneCoercer),
  periods: array(string()),
  keywords: nullable(size(array(string()), 1, 5)),
  summary: nullable(string()),
  compliances: array(ComplianceBeneficiarySchema),
  commitments: array(CommitmentBeneficiarySchema),
  actions: array(ActionSchema),
  descriptions: array(DescriptionBeneficiarySchema),
  indicators: array(BeneficiaryIndicatorSchema),
  expenses: array(ExpenseBeneficiarySchema),
  attachments: array(AttachmentBeneficiarySchema),
  annexes: array(AnnexBeneficiarySchema),
});

export type ProjectDraft = Infer<typeof ProjectDraftSchema>;

// A venir
// // Project
// export const ProjectBeneficiarySchema = object({
//   id: string(),
//   type: enums(Object.values(ProgramKey)),
//   label: nullable(string()),
//   owner: UserSchema,
//   organization: OrganizationSchema,
//   contactName: string(),
//   contactPhoneNumber: phoneCoercer,
//   contactMobilePhoneNumber: phoneCoercer,
//   keywords: size(array(string()), 1, 5),
//   summary: string(),
//   periods: array(string()),
//   compliances: array(ComplianceInstructorSchema),
//   commitments: array(CommitmentInstructorSchema),
//   actions: array(ActionSchema),
//   descriptions: array(DescriptionInstructorSchema),
//   indicators: array(IndicatorWithMakersAndAnalysisSchema),
//   expenses: array(ExpenseInstructorSchema),
//   proposals: array(ProposalSchema),
//   attachments: array(AttachmentInstructorSchema),
//   annexes: array(AnnexSchema),
// });

// export type ProjectBeneficiary = Infer<typeof ProjectBeneficiarySchema>;

// ProjectInstructor

export const ProjectInstructorSchema = object({
  id: string(),
  type: enums(Object.values(ProgramKey)),
  label: string(),
  owner: UserSchema,
  status: ProjectStatusSchema,
  organization: OrganizationSchema,
  contactName: string(),
  contactPhoneNumber: phoneCoercer,
  contactMobilePhoneNumber: phoneCoercer,
  keywords: size(array(string()), 1, 5),
  summary: string(),
  periods: array(string()),
  compliances: array(ComplianceInstructorSchema),
  commitments: array(CommitmentInstructorSchema),
  actions: array(ActionSchema),
  descriptions: array(DescriptionInstructorSchema),
  indicators: array(InstructorIndicatorSchema),
  expenses: array(ExpenseInstructorSchema),
  proposals: array(ProposalSchema),
  attachments: array(AttachmentInstructorSchema),
  annexes: array(AnnexSchema),
  investigation: nullable(InvestigationSchema),
  notations: array(NotationSchema),
  report: nullable(string()),
  instructorSignature: nullable(SignatureSchema),
});

export type ProjectInstructor = Infer<typeof ProjectInstructorSchema>;

// ProjectSummary

export const ProjectSummarySchema = object({
  id: string(),
  status: ProjectStatusSchema,
  type: ProgramKeySchema,
  organization: OrganizationSchema,
  amount: nullable(number()),
  label: string(),
});

export type ProjectSummary = Infer<typeof ProjectSummarySchema>;

// ProjectReference

export const ProjectReferenceSchema = object({
  id: string(),
  type: ProgramKeySchema,
  label: string(),
  periods: array(string()),
});

export type ProjectReference = Infer<typeof ProjectReferenceSchema>;

// ProjectCreation
export const ProjectCreationSchema = object({
  label: string(),
  type: ProgramKeySchema,
  owner: string(),
  organization: string(),
  contactName: nullable(string()),
  contactPhoneNumber: nullable(phoneCoercer),
  contactMobilePhoneNumber: nullable(phoneCoercer),
  keywords: nullable(size(array(string()), 0, 5)),
  summary: nullable(string()),
});

export type ProjectCreation = Infer<typeof ProjectCreationSchema>;

export const ProjectUpdateSchema = object({
  label: optional(string()),
  contactPhoneNumber: optional(nullable(phoneCoercer)),
  contactMobilePhoneNumber: optional(nullable(phoneCoercer)),
  keywords: optional(size(array(string()), 0, 5)),
  summary: optional(nullable(string())),
});

export type ProjectUpdate = Infer<typeof ProjectUpdateSchema>;
