import axios, { AxiosInstance, isAxiosError } from "axios";
import WhoAmI from "./WhoAmI";
import {
  BeneficiaryAchievementEdition,
  InstructorAchievementEdition,
} from "@hpo/client/models/Achievement";
import { Analysis } from "@hpo/client/models/Analysis";
import {
  BeneficiaryPaymentDetails,
  BeneficiaryPaymentsByConvention,
} from "@hpo/client/models/BeneficiaryPayment";
import { CommitmentBeneficiary } from "@hpo/client/models/Commitment";
import {
  ConventionCreation,
  ConventionDetails,
  ConventionEdition,
  ConventionSummary,
} from "@hpo/client/models/Convention";
import { ExpenseInstructor, ExpenseCreation } from "@hpo/client/models/Expense";
import {
  InstructorPaymentDetails,
  InstructorPaymentSummary,
  InstructorPaymentUpdate,
  InstructorPaymentValidation,
} from "@hpo/client/models/InstructorPayment";
import { InvestigationCreation } from "@hpo/client/models/Investigation";
import { Message } from "@hpo/client/models/Message";
import {
  Organization,
  OrganizationCreation,
} from "@hpo/client/models/Organization";
import { ReceiptUpdate } from "@hpo/client/models/Receipt";
import {
  ProjectInstructor,
  ProjectDraft,
  ProjectCreation,
  ProjectSummary,
  ProjectUpdate,
} from "@hpo/client/models/Project";
import { UserAccess, UserCreation, UserUpdate } from "@hpo/client/models/User";
import createInjectable from "@hpo/client/utilities/createInjectable";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import { ComplianceBeneficiary } from "@hpo/client/models/Compliance";
import { IndicatorPayload } from "@hpo/client/models/Indicator";
import { ActionCreation } from "@hpo/client/models/Action";
import ProgramKey from "@hpo/client/utilities/enums/ProgramKey";
import ExpenseType from "@hpo/client/utilities/enums/ExpenseType";
import {
  ProgramAvailability,
  ProgramAvailabilityPayload,
} from "@hpo/client/models/Program";
import { Tag, TagCreation } from "@hpo/client/models/Tag";
import Exception from "@hpo/client/utilities/errors/Exception";
import {
  OverallStatistics,
  ProjectsPerformance,
  TagsPerformance,
} from "@hpo/client/models/Yearly";

export default class ServerSdk {
  private anonymousInstance: AxiosInstance;
  private loggedinInstance: AxiosInstance;

  constructor(
    baseURL: string,
    private whoAmI: WhoAmI,
  ) {
    this.anonymousInstance = axios.create({
      baseURL,
      withCredentials: true,
    });
    this.setupCookieReadOnAxiosResponse(this.anonymousInstance);
    this.setupMessageErrorDetection(this.anonymousInstance);

    this.loggedinInstance = axios.create({
      baseURL,
      withCredentials: true,
    });
    this.setupCookieReadOnAxiosResponse(this.loggedinInstance);
    this.setupMessageErrorDetection(this.loggedinInstance);
  }

  private setupCookieReadOnAxiosResponse(instance: AxiosInstance) {
    instance.interceptors.response.use(
      (response) => {
        this.whoAmI.readCookies();
        return response;
      },
      (error) => {
        this.whoAmI.readCookies();
        return Promise.reject(error);
      },
    );
  }

  private setupMessageErrorDetection(instance: AxiosInstance) {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (isAxiosError(error) && error.code === "ERR_NETWORK") {
          throw new NetworkError({ cause: error });
        }

        if (
          isAxiosError(error) &&
          error.response &&
          error.response.data &&
          MessageException.isJson(error.response.data)
        ) {
          const messageException = MessageException.fromJson(
            error.response.data,
          );
          throw messageException;
        } else {
          throw error;
        }
      },
    );
  }

  // Login

  async login(email: string, password: string) {
    await this.anonymousInstance.post<void>("/login", {
      email,
      password,
    });
  }

  async refreshLogin() {
    await this.anonymousInstance.post<void>("/login/refresh");
  }

  async logout() {
    await this.loggedinInstance.delete<void>("/login");
  }

  // Projects

  async getProjects() {
    const res =
      await this.loggedinInstance.get<Array<ProjectSummary>>("/projects");
    return res.data;
  }

  async getProject(id: string) {
    const res = await this.loggedinInstance.get<ProjectInstructor>(
      `/projects/${id}`,
    );
    return res.data;
  }

  async updateProject(
    id: string,
    updates: Partial<Pick<ProjectInstructor, "label" | "periods">>,
  ) {
    const res = await this.loggedinInstance.patch<ProjectInstructor>(
      `/projects/${id}`,
      updates,
    );
    return res.data;
  }

  async updateExpenseAmountProposed(
    id: string,
    type: ExpenseType,
    proposal: number | null,
  ) {
    const res = await this.loggedinInstance.patch<ExpenseInstructor>(
      `/expenses/${id}`,
      {
        id,
        type,
        proposal,
      },
    );
    return res.data;
  }

  async createIndicator(
    projectId: string,
    indicator: IndicatorPayload,
    sort: number,
  ) {
    const res = await this.loggedinInstance.post<string>(
      `/projects/${projectId}/indicators`,
      indicator,
      { params: { sort } },
    );
    return res.data;
  }

  async updateIndicator(
    projectId: string,
    indicatorId: string,
    indicator: IndicatorPayload,
  ) {
    const res = await this.loggedinInstance.patch<string>(
      `/projects/${projectId}/indicators/${indicatorId}`,
      indicator,
    );
    return res.data;
  }

  async updateIndicatorTags(
    projectId: string,
    indicatorId: string,
    tags: Array<string>,
  ) {
    const res = await this.loggedinInstance.patch<string>(
      `/projects/${projectId}/indicators/${indicatorId}/tags`,
      { tags },
    );
    return res.data;
  }

  async deleteIndicator(projectId: string, indicatorId: string) {
    const res = await this.loggedinInstance.delete<void>(
      `/projects/${projectId}/indicators/${indicatorId}`,
    );
    return res.data;
  }

  // Project Draft (beneficiaries)
  async createMyProject(req: ProjectCreation) {
    const res = await this.loggedinInstance.post<string>(
      "/users/me/projects",
      req,
    );
    return res.data;
  }

  async getMyProjects() {
    const res =
      await this.loggedinInstance.get<Array<ProjectSummary>>(
        `/users/me/projects`,
      );
    return res.data;
  }

  async getMyProject(id: string) {
    const res = await this.loggedinInstance.get<ProjectDraft>(
      `/users/me/projects/${id}`,
    );
    return res.data;
  }

  async updateMyProject(id: string, project: ProjectUpdate) {
    const res = await this.loggedinInstance.patch<string>(
      `/users/me/projects/${id}`,
      project,
    );
    return res.data;
  }

  async validateProject(id: string) {
    const res = await this.loggedinInstance.patch<string>(
      `/users/me/projects/${id}/validate`,
    );
    return res.data;
  }

  async updateCommitment(
    projectId: string,
    commitmentId: string,
    validated: boolean,
  ) {
    const res = await this.loggedinInstance.patch<CommitmentBeneficiary>(
      `/users/me/projects/${projectId}/commitment/${commitmentId}`,
      { validated },
    );
    return res.data;
  }

  async updateCompliance(
    projectId: string,
    complianceId: string,
    validated: boolean,
  ) {
    const res = await this.loggedinInstance.patch<ComplianceBeneficiary>(
      `/users/me/projects/${projectId}/compliance/${complianceId}`,
      { validated },
    );
    return res.data;
  }

  async createMyIndicator(
    projectId: string,
    indicator: IndicatorPayload,
    sort: number,
  ) {
    const res = await this.loggedinInstance.post<string>(
      `/users/me/projects/${projectId}/indicators`,
      indicator,
      { params: { sort } },
    );
    return res.data;
  }

  async updateMyIndicator(
    projectId: string,
    indicatorId: string,
    indicator: IndicatorPayload,
  ) {
    const res = await this.loggedinInstance.patch<string>(
      `/users/me/projects/${projectId}/indicators/${indicatorId}`,
      indicator,
    );
    return res.data;
  }

  async deleteMyIndicator(projectId: string, indicatorId: string) {
    const res = await this.loggedinInstance.delete<void>(
      `/users/me/projects/${projectId}/indicators/${indicatorId}`,
    );
    return res.data;
  }

  async createExpense(projectId: string, expense: ExpenseCreation) {
    const res = await this.loggedinInstance.post<string>(
      `/users/me/projects/${projectId}/expenses`,
      expense,
    );
    return res.data;
  }

  async updateExpense(
    projectId: string,
    expenseId: string,
    expense: ExpenseCreation,
  ) {
    const res = await this.loggedinInstance.patch<string>(
      `/users/me/projects/${projectId}/expenses/${expenseId}`,
      expense,
    );
    return res.data;
  }

  async deleteExpense(projectId: string, expenseId: string) {
    const res = await this.loggedinInstance.delete<void>(
      `/users/me/projects/${projectId}/expenses/${expenseId}`,
    );
    return res.data;
  }

  async createAction(projectId: string, action: ActionCreation) {
    const res = await this.loggedinInstance.post<string>(
      `/users/me/projects/${projectId}/actions`,
      action,
    );
    return res.data;
  }

  async updateAction(
    projectId: string,
    actionId: string,
    action: ActionCreation,
  ) {
    const res = await this.loggedinInstance.patch<string>(
      `/users/me/projects/${projectId}/actions/${actionId}`,
      action,
    );
    return res.data;
  }

  async deleteAction(projectId: string, actionId: string) {
    const res = await this.loggedinInstance.delete<string>(
      `/users/me/projects/${projectId}/actions/${actionId}`,
    );
    return res.data;
  }

  async updateAttachment(
    project: string,
    fileKey: string,
    uploads: Array<string>,
  ) {
    const res = await this.loggedinInstance.patch<void>(
      `/users/me/projects/${project}/attachments/${fileKey}`,
      { uploads },
    );
    return res.data;
  }

  async updateDescription(project: string, id: string, value: string | null) {
    const res = await this.loggedinInstance.patch<void>(
      `/users/me/projects/${project}/descriptions/${id}`,
      { value },
    );
    return res.data;
  }

  // Organization

  async getOrganizations() {
    const res =
      await this.loggedinInstance.get<Array<Organization>>("/organizations");
    return res.data;
  }

  async createOrganization(payload: OrganizationCreation) {
    const res = await this.loggedinInstance.post<string>(
      `/organizations`,
      payload,
    );
    return res.data;
  }

  async updateOrganization(id: string, payload: OrganizationCreation) {
    await this.loggedinInstance.patch<void>(`/organizations/${id}`, payload);
  }

  // Attachements

  async uploadAttachment(req: string, fileKey: string, uploads: Array<string>) {
    const res = await this.loggedinInstance.post<void>(
      `/projects/${req}/attachments/${fileKey}`,
      { uploads },
    );
    return res.data;
  }

  // Analysis

  async saveAnalysis(resource: string, analysis: Analysis) {
    const res = await this.loggedinInstance.put<string>(
      `/analyses/${resource}`,
      analysis,
    );
    return res.data;
  }

  // Investigation

  async createInvestigation(request: string, invest: InvestigationCreation) {
    await this.loggedinInstance.post<void>(
      `/projects/${request}/investigation`,
      invest,
    );
  }

  async createProjectReport(project: string) {
    await this.loggedinInstance.post<void>(`/projects/${project}/report`);
  }

  // Me

  async updateMyPassword(password: string) {
    await this.loggedinInstance.post<void>(`/me/password`, { password });
  }

  // IAm

  async getUsers() {
    const res = await this.loggedinInstance.get<Array<UserAccess>>(`/users`);
    return res.data;
  }

  async createUser(payload: UserCreation) {
    const res = await this.loggedinInstance.post<string>(`/users`, payload);
    return res.data;
  }

  async updateUser(id: string, payload: UserUpdate) {
    await this.loggedinInstance.patch<void>(`/users/${id}`, payload);
  }

  async updateUserPassword(id: string) {
    await this.loggedinInstance.post<void>(`/users/${id}/password`);
  }

  async deleteUser(id: string) {
    await this.loggedinInstance.delete<void>(`/users/${id}`);
  }
  // Uploads

  async uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const res = await this.loggedinInstance.post<string>(`/uploads`, formData);
    return res.data;
  }

  // Convention

  async getOrganizationProjects(org: string) {
    const res = await this.loggedinInstance.get<Array<ProjectSummary>>(
      `/organizations/${org}/projects`,
    );
    return res.data;
  }

  async createConvention(convention: ConventionCreation): Promise<string> {
    const res = await this.loggedinInstance.post<string>(
      "/conventions",
      convention,
    );
    return res.data;
  }

  async getConventions() {
    const res =
      await this.loggedinInstance.get<Array<ConventionSummary>>("/conventions");
    return res.data;
  }

  async getConvention(id: string) {
    const res = await this.loggedinInstance.get<ConventionDetails>(
      `/conventions/${id}`,
    );
    return res.data;
  }

  async updateConvention(id: string, updates: ConventionEdition) {
    const res = await this.loggedinInstance.patch<ConventionDetails>(
      `/conventions/${id}`,
      updates,
    );
    return res.data;
  }

  async sendPaymentsAvailableMail(convention: string) {
    await this.loggedinInstance.post(
      `/conventions/${convention}/payments-available-mail`,
    );
  }

  async cancelPaymentReceipts(convention: string, payment: string) {
    await this.loggedinInstance.delete<void>(
      `/conventions/${convention}/payments/${payment}/receipts`,
    );
  }

  // Achievements

  async updateAchievementIndicatorNotes(
    achievement: string,
    updates: Pick<InstructorAchievementEdition, "indicatorNotes">,
  ) {
    await this.loggedinInstance.patch<void>(
      `/achievements/${achievement}/indicatorNotes`,
      updates,
    );
  }

  async updateAchievement(
    achievement: string,
    updates: Pick<InstructorAchievementEdition, "grantedAmount">,
  ) {
    await this.loggedinInstance.patch<void>(
      `/achievements/${achievement}`,
      updates,
    );
  }

  // Update receipts

  async updateReceipt(receipt: string, updates: ReceiptUpdate) {
    await this.loggedinInstance.patch<void>(`/receipts/${receipt}`, updates);
  }

  // Messages

  async getMessages(target: string | true) {
    const res = await this.loggedinInstance.get<Array<Message>>(
      `/chats/${target === true ? "mine" : target}/messages`,
    );
    return res.data;
  }

  async sendMessage(target: string | true, message: string) {
    await this.loggedinInstance.post<void>(
      `/chats/${target === true ? "mine" : target}/messages`,
      { content: message },
    );
  }

  // My payments

  async getMyPayments() {
    const res =
      await this.loggedinInstance.get<BeneficiaryPaymentsByConvention>(
        `/users/me/payments`,
      );
    return res.data;
  }

  async getMyPayment(id: string): Promise<BeneficiaryPaymentDetails> {
    const res = await this.loggedinInstance.get<BeneficiaryPaymentDetails>(
      `/users/me/payments/${id}`,
    );
    return res.data;
  }

  // Payments

  async getPayments() {
    const res =
      await this.loggedinInstance.get<Array<InstructorPaymentSummary>>(
        `/payments`,
      );
    return res.data;
  }

  async getPayment(id: string) {
    const res = await this.loggedinInstance.get<InstructorPaymentDetails>(
      `/payments/${id}`,
    );
    return res.data;
  }

  async sendPaymentRequest(
    payment: string,
    achievments: Array<BeneficiaryAchievementEdition>,
  ): Promise<void> {
    await this.loggedinInstance.patch<void>(`/users/me/payments/${payment}`, {
      achievments,
    });
  }

  async updatePayment(id: string, update: InstructorPaymentUpdate) {
    await this.loggedinInstance.patch<void>(`/payments/${id}`, update);
  }

  async createPaymentExport(id: string) {
    const res = await this.loggedinInstance.post(
      `/payments/${id}/export`,
      undefined,
      { responseType: "blob" },
    );
    return res.data;
  }

  async deletePaymentReport(id: string, message: string | null) {
    const res = await this.loggedinInstance.delete(`/payments/${id}/report`, {
      data: { message },
    });
    return res.data;
  }

  async deletePaymentInstruction(id: string) {
    const res = await this.loggedinInstance.delete(
      `/payments/${id}/instruction`,
    );
    return res.data;
  }

  async deletePayment(id: string) {
    const res = await this.loggedinInstance.delete(`/payments/${id}`);
    return res.data;
  }

  async validatePayment(id: string, valdation: InstructorPaymentValidation) {
    await this.loggedinInstance.post<void>(
      `/payments/${id}/validation`,
      valdation,
    );
  }

  async getWrongAttachments() {
    return await this.loggedinInstance.get<Array<string>>(
      `/temp/wrong-attachments`,
    );
  }

  // ProjectTypes

  async getProjectTypes() {
    const output =
      await this.loggedinInstance.get<Array<ProgramAvailability>>(
        `/project-types`,
      );
    return output.data;
  }

  async getVisibleProjectTypes() {
    const output = await this.loggedinInstance.get<Array<ProgramAvailability>>(
      `/project-types`,
      { params: { visible: "true" } },
    );
    return output.data;
  }

  async updateProjectType(
    key: ProgramKey,
    payload: ProgramAvailabilityPayload,
  ) {
    await this.loggedinInstance.patch(`/project-types/${key}`, payload);
  }

  // Tags
  async getTags() {
    const res = await this.loggedinInstance.get<Array<Tag>>(`/tags`);
    return res.data;
  }

  async getTag(id: string) {
    const res = await this.loggedinInstance.get<Tag>(`/tags/${id}`);
    return res.data;
  }

  async createTag(tag: TagCreation) {
    const res = await this.loggedinInstance.post<string>(`/tags`, tag);
    return res.data;
  }

  async updateTag(id: string, tag: TagCreation) {
    const res = await this.loggedinInstance.patch<string>(`/tags/${id}`, tag);
    return res.data;
  }

  // Periods

  async getPeriods() {
    const res = await this.loggedinInstance.get<Array<string>>("/periods");
    return res.data;
  }

  async getOverallStatistics(periods: Array<string>) {
    const res = await this.loggedinInstance.get<OverallStatistics>(
      "/overall-statistics",
      { params: { periods } },
    );
    return res.data;
  }

  async getTagsPerformance(period: string) {
    const res = await this.loggedinInstance.get<TagsPerformance>(
      "/tags-performance",
      { params: { period } },
    );
    return res.data;
  }

  async getProjectsPerformance(periods: Array<string>) {
    const res = await this.loggedinInstance.get<ProjectsPerformance>(
      "/projects-performance",
      { params: { periods } },
    );
    return res.data;
  }
}

export const [ServerSdkProvider, useServerSdk] =
  createInjectable<ServerSdk>("ServerSdk");

export class NetworkError extends Exception {}
