import { Global, css } from "@emotion/react";
import { Fragment, ReactNode, useMemo } from "react";
import { createPortal } from "react-dom";
import { useBrowserLocation } from "wouter/use-browser-location";
import { intersection } from "lodash";
import dayjs from "dayjs";
import logo from "./assets/logo.svg";
import { useWhoAmI } from "./services/WhoAmI";
import { useServerSdk } from "./services/ServerSdk";
import Config from "./services/Config";
import Button from "@hpo/client/components/Button";
import Spacer, { SPACING_BASE } from "@hpo/client/utilities/Spacer";
import {
  getRoleLabel,
  getUserName,
  isUserAuthorized,
} from "@hpo/client/utilities/helpers/UserHelper";
import {
  Route,
  conventionsRoute,
  homeRoute,
  myPaymentsRoute,
  myProjectsRoute,
  paymentsRoute,
  projectsRoute,
  managementRoute,
  yearlyReportRoute,
} from "@hpo/client/utilities/routes";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Clickable from "@hpo/client/components/Clickable";
import T from "@hpo/client/components/text/Text";
import Theme from "@hpo/client/utilities/Theme/client";
import Role from "@hpo/client/utilities/enums/Role";

export default function Menu() {
  const token = useWhoAmI().use();
  const user = token ? token.getPayload() : null;
  const showMenu = user && isUserAuthorized(user);

  const server = useServerSdk();

  const el = useMemo(() => {
    const existing = document.getElementById("menu");
    if (existing) return existing;
    const el = document.createElement("menu");
    el.id = "menu";
    document.body.prepend(el);
    return el;
  }, []);

  const globalCss = css({
    body: {
      paddingLeft: "17vw",
    },
  });

  const containerCss = css({
    position: "fixed",
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: 1000,
    width: "17vw",
    display: "flex",
    flexDirection: "column",
  });

  const imageCss = css({
    width: "40%",
    margin: "10%",
  });

  const menuWrapper = css({
    flex: 1,
    flexDirection: "column",
  });

  const menuCss = css({
    flex: "1",
    background: "white",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
    opacity: 0.9,
    padding: SPACING_BASE,
  });

  const [onLogout, logout] = useSubmitCallback(async () => {
    await server.logout();
  }, []);

  let meNode: ReactNode = null;
  if (user) {
    meNode = (
      <div
        css={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <T>{getUserName(user)}</T>
        {user.roles.includes(Role.Benficiary) && user.organization && (
          <T>({user.organization.businessName})</T>
        )}
        {user.roles.map((r) => (
          <T style="minor" key={r} tag="div" opacity={0.7}>
            {getRoleLabel(r)}
          </T>
        ))}
        <Spacer />
        <Button
          onClick={onLogout}
          loading={logout.running}
          label="Se déconnecter"
        />
        <Spacer />
        <T style="minor" center>
          Dernière mise à jour le
          <br />
          {dayjs(Config.build_date).format("LLLL")}
        </T>
      </div>
    );
  }

  let menuNode: ReactNode = null;

  const managementRoles = useMemo<Array<Role>>(
    () => [Role.Instructor, Role.Level1, Role.Level2, Role.Executor],
    [],
  );
  const hasManagementtTabs = useMemo(
    () => user && intersection(user.roles, managementRoles).length,
    [user, managementRoles],
  );

  const hasMyProjectsTab = useMemo(
    () => user && intersection(user.roles, [Role.Benficiary]).length,
    [user],
  );

  const hasMyPaymentsTab = useMemo(
    () => user && intersection(user.roles, [Role.Benficiary]).length,
    [user],
  );

  const hasUsersTab = useMemo(
    () => user && intersection(user.roles, [Role.Manager]).length,
    [user],
  );

  const hasReportTab = useMemo(
    () =>
      user &&
      intersection(user.roles, [Role.Instructor, Role.Level1, Role.Level2])
        .length,
    [user],
  );

  if (showMenu) {
    const listStyle = css({
      listStyleType: "none",
      padding: 0,
      margin: 0,
    });

    menuNode = (
      <div css={menuCss}>
        <ul css={listStyle}>
          <MenuItemView label="Accueil" route={homeRoute} />
          {hasManagementtTabs ? (
            <Fragment>
              <MenuItemView label="Projets" route={projectsRoute} />
              <MenuItemView label="Conventions" route={conventionsRoute} />
              <MenuItemView
                label="Paiements à instruire"
                route={paymentsRoute}
              />
            </Fragment>
          ) : null}
          {hasMyProjectsTab ? (
            <MenuItemView label="Mes projets" route={myProjectsRoute} />
          ) : null}
          {hasMyPaymentsTab ? (
            <MenuItemView label="Mes paiements" route={myPaymentsRoute} />
          ) : null}
          {hasUsersTab ? (
            <MenuItemView
              label="Gestion de la plateforme"
              route={managementRoute}
            />
          ) : null}
          {hasReportTab ? (
            <MenuItemView label="Rapport annuel" route={yearlyReportRoute} />
          ) : null}
        </ul>
      </div>
    );
  }

  return createPortal(
    <Fragment>
      <Global styles={globalCss} />
      <div css={containerCss}>
        <img src={logo} alt="Logo de la Réunion" css={imageCss} />
        <div css={menuWrapper}>{menuNode}</div>
        <Spacer size={2} />
        {meNode}
        <Spacer />
      </div>
    </Fragment>,
    el,
  );
}

type MenuItemViewProps = {
  label: string;
  route: Route<void>;
  subItems?: MenuItemViewProps[];
};

function MenuItemView(props: MenuItemViewProps) {
  const { label, route, subItems } = props;

  const [location] = useBrowserLocation();
  const selected = route.matchesRootUrl(location);

  return (
    <li>
      <Clickable to={route.getUrl()}>
        <T color={selected ? Theme.color : Theme.text.normal}>• {label}</T>
      </Clickable>
      {subItems && (
        <ul>
          {subItems.map((p, i) => (
            <MenuItemView {...p} key={i} />
          ))}
        </ul>
      )}
    </li>
  );
}
