import { Fragment, useEffect, useMemo } from "react";
import { SerializedStyles, css } from "@emotion/react";
import { rgba } from "polished";
import Button from "../components/Button";
import T from "../components/text/Text";
import { useLogger } from "../logger";
import Spacer from "./Spacer";
import Theme from "./Theme/client";
import getErrorMessage from "./errors/getErrorMessage";

type ErrorViewProps = {
  error: unknown;
  noLog?: boolean;
  retry?: {
    label: string;
    callback: () => void;
  };
};

export default function ErrorView(props: ErrorViewProps) {
  const { error, retry, noLog } = props;

  const logger = useLogger();

  const message = useMemo(() => {
    if (!error) return null;
    return getErrorMessage(error);
  }, [error]);

  useEffect(() => {
    if (!error || noLog) return;
    logger.error(error);
  }, [error, noLog]);

  if (!message) return null;

  return (
    <Fragment>
      <Message severity="error" text={message} />
      {retry && (
        <Fragment>
          <Spacer />
          <Button label={retry.label} onClick={retry.callback} />
        </Fragment>
      )}
    </Fragment>
  );
}

type MessageSeverity = "error";

const stylePerSeverity: Record<MessageSeverity, SerializedStyles> = {
  error: css({
    background: rgba(Theme.error, 0.1),
    borderColor: Theme.error,
    color: Theme.error,
  }),
};

type MessageProps = {
  severity: MessageSeverity;
  text: string;
};

function Message(props: MessageProps) {
  const { severity } = props;
  const containerCss = css(stylePerSeverity[severity], {
    padding: 10,
    borderRadius: 6,
    borderWidth: 0,
    borderLeftWidth: 3,
    borderStyle: "solid",
    display: "inline-block",
  });

  return (
    <div css={containerCss}>
      <T>{props.text}</T>
    </div>
  );
}
