import Badge from "./Badge";
import ProjectStatus from "./enums/ProjectStatus";
import Theme from "./Theme/client";

type ProjectStatusBadgeProps = {
  status: ProjectStatus;
};

export default function ProjectStatusBadge(props: ProjectStatusBadgeProps) {
  const { status } = props;
  return (
    <Badge color={ProjectStatusColors[status]}>
      {ProjectStatusLabels[status]}
    </Badge>
  );
}

export const ProjectStatusLabels: Record<ProjectStatus, string> = {
  [ProjectStatus.Draft]: "Brouillon",
  [ProjectStatus.UnderInstruction]: "En cours d'instruction",
  [ProjectStatus.Done]: "Instruction terminée",
};

export const ProjectStatusColors: Record<ProjectStatus, string> = {
  [ProjectStatus.Draft]: Theme.error,
  [ProjectStatus.UnderInstruction]: Theme.warning,
  [ProjectStatus.Done]: Theme.color,
};
