import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { sortBy } from "lodash";
import dayjs from "dayjs";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import ProjectInquiryStatus from "../../ProjectNavigator/ProjectScreen/inquiry/ProjectInquiryStatus";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import { useWhoAmI } from "../../RootNavigator/services/WhoAmI";
import Table from "./Table";
import { PaymentViewProps } from ".";
import { InstructorAchievement } from "@hpo/client/models/Achievement";
import Spacer from "@hpo/client/utilities/Spacer";
import T from "@hpo/client/components/text/Text";
import Tabs from "@hpo/client/utilities/Tabs";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { Analysis } from "@hpo/client/models/Analysis";
import {
  ReceiptDescriptor,
  getReceiptsForRequestTypeAndPaymentFragment,
} from "@hpo/client/utilities/Receipts";
import Sections from "@hpo/client/utilities/Sections";
import FilesField from "@hpo/client/utilities/fields/FilesField";
import TextField from "@hpo/client/utilities/fields/TextField";
import Columns from "@hpo/client/utilities/Columns";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import { ReceiptWithAnalysis } from "@hpo/client/models/Receipt";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Toasting from "@hpo/client/components/Toasting";
import IndicatorType, {
  IndicatorTypeLabels,
} from "@hpo/client/utilities/enums/IndicatorType";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import IndicatorOrigin from "@hpo/client/utilities/enums/IndicatorOrigin";
import { getMarkers, Marker } from "@hpo/client/models/Marker";
import Units from "@hpo/client/utilities/Units";
import { InstructorIndicator } from "@hpo/client/models/Indicator";
import Button from "@hpo/client/components/Button";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import Role from "@hpo/client/utilities/enums/Role";

export default function PaymentReceipts(props: PaymentViewProps) {
  const { payment } = props;

  return (
    <Fragment>
      <T style="subtitle">Justificatifs</T>
      <Spacer />
      <Tabs showSingleTab>
        {payment.achievements
          .sort((achievmentA, achievmentB) => {
            return getProjectLabel(achievmentA.funding.project).localeCompare(
              getProjectLabel(achievmentB.funding.project),
            );
          })
          .map((a) => (
            <Tabs.Tab
              title={getProjectLabel(a.funding.project)}
              id={a.id}
              key={a.id}
            >
              <AchievementView {...props} achievement={a} />
            </Tabs.Tab>
          ))}
      </Tabs>
    </Fragment>
  );
}

type AchievementViewProps = PaymentViewProps & {
  achievement: InstructorAchievement;
  onRefresh: () => unknown;
};

function AchievementView(props: AchievementViewProps) {
  const { payment, achievement, onRefresh } = props;

  const server = useServerSdk();
  const whoami = useWhoAmI();
  const user = whoami.useUser();

  const descriptors = useMemo(() => {
    return getReceiptsForRequestTypeAndPaymentFragment(
      achievement.funding.project.type,
      payment.fragment,
    );
  }, [achievement.funding.project.type, payment.fragment]);

  const [indicatorNotes, setIndicatorNotes] = useState<string | null>(
    achievement.indicatorNotes,
  );

  const iAmInstructor = useMemo(() => {
    return user.roles.includes(Role.Instructor);
  }, [user.roles]);

  const [onAchievmentUpdate, achievmentUpdating] =
    useSubmitCallback(async () => {
      await server.updateAchievementIndicatorNotes(achievement.id, {
        indicatorNotes,
      });
      Toasting.success(
        "OK",
        "Le commentaire sur les indicateurs d'évaluation a bien été enregistré",
      );
      onRefresh();
    }, [indicatorNotes, achievement]);

  return (
    <Fragment>
      <T style="section">Documents</T>
      <Spacer />
      <Sections>
        {descriptors.map((d) => {
          const receipt = achievement.receipts.find((r) => r.key === d.key);
          return (
            <ReceiptView
              {...props}
              descriptor={d}
              receipt={receipt || null}
              key={d.key}
            />
          );
        })}
      </Sections>
      <Spacer />
      <T style="section">Indicateurs d'évaluation</T>
      <Spacer />
      <Table>
        <IndicatorTablePart
          origin={IndicatorOrigin.Beneficiary}
          type={IndicatorType.Achievement}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
        <IndicatorTablePart
          origin={IndicatorOrigin.Beneficiary}
          type={IndicatorType.Result}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
        <IndicatorTablePart
          origin={IndicatorOrigin.Beneficiary}
          type={IndicatorType.Communication}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
        <IndicatorTablePart
          origin={IndicatorOrigin.Beneficiary}
          type={IndicatorType.Transfert}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
      </Table>
      <Spacer />
      <FieldsForm onSubmit={onAchievmentUpdate}>
        <TextField
          textArea
          value={indicatorNotes}
          label="Commentaire sur les indicateurs d'évaluation"
          onChange={setIndicatorNotes}
          disabled={!iAmInstructor}
        />
        {iAmInstructor && (
          <Fragment>
            <Spacer scale={0.5} />
            <Button
              submit
              label="Valider"
              loading={achievmentUpdating.running}
            />
          </Fragment>
        )}
        <ErrorToast error={achievmentUpdating.error} />
      </FieldsForm>
      <Spacer />
      <T style="section">Indicateurs d'impact</T>
      <Spacer />
      <Table>
        <IndicatorTablePart
          origin={IndicatorOrigin.Instructor}
          type={IndicatorType.Achievement}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
        <IndicatorTablePart
          origin={IndicatorOrigin.Instructor}
          type={IndicatorType.Result}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
        <IndicatorTablePart
          origin={IndicatorOrigin.Instructor}
          type={IndicatorType.Communication}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
        <IndicatorTablePart
          origin={IndicatorOrigin.Instructor}
          type={IndicatorType.Transfert}
          indicators={achievement.funding.project.indicators}
          periods={achievement.funding.project.periods}
        />
      </Table>
      <Spacer />
      <T style="section">Demande</T>
      <Spacer />
      <Columns columns={2}>
        <NumberField
          value={achievement.requestedAmount}
          label="Montant demandé"
          unit="euro"
          readonly
          allowZero
        />
        <TextField
          textArea
          value={achievement.requestNotes}
          label="Commentaire"
          readonly
        />
      </Columns>
      <Spacer />
    </Fragment>
  );
}

type IndicatorTablePartProps = {
  origin: IndicatorOrigin;
  type: IndicatorType;
  periods: Array<string>;
  indicators: Array<InstructorIndicator>;
};

function IndicatorTablePart(props: IndicatorTablePartProps) {
  const { origin, type, periods, indicators } = props;

  const filtered = useMemo(
    () => indicators.filter((i) => i.type === type && i.origin === origin),
    [type, indicators],
  );

  if (filtered.length === 0) return null;

  return (
    <Fragment>
      <thead>
        <tr>
          <th>{IndicatorTypeLabels[type]}</th>
          {periods.map((p) => (
            <th key={p}>{p}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filtered.map((i) => {
          return (
            <tr key={i.id}>
              <td>{i.label}</td>
              {periods.map((p) => {
                return (
                  <td key={p}>
                    <IndicatorMarkersOnPeriod indicator={i} period={p} />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Fragment>
  );
}

type ReceiptViewProps = AchievementViewProps & {
  descriptor: ReceiptDescriptor;
  receipt: ReceiptWithAnalysis | null;
};

function ReceiptView(props: ReceiptViewProps) {
  const { payment, descriptor, receipt, onRefresh } = props;

  const server = useServerSdk();

  const saveAnalysis = useCallback(
    async (resourceId: string, analysis: Analysis) => {
      await server.saveAnalysis(resourceId, analysis);
      await onRefresh();
    },
    [server, onRefresh],
  );

  const [files, setFiles] = useState<Array<string> | null>(() =>
    receipt ? receipt.files : [],
  );

  const [onFilesChange, changeFiles] = useSubmitCallback(
    async (files: Array<string> | null) => {
      setFiles(files);
      if (!receipt) throw new Error("Missing receipt");
      await server.updateReceipt(receipt.id, { files });
      Toasting.success("Ok", `Documents mis à jour !`);
    },
    [setFiles, receipt, files],
  );

  return (
    <Sections.Section
      key={descriptor.key}
      id={descriptor.key}
      title={descriptor.label}
      right={
        receipt && (
          <ProjectInquiryStatus
            analysis={receipt.analysis}
            onChange={(analysis) => saveAnalysis(receipt.id, analysis)}
            readonly={payment.status !== "under-instruction"}
          />
        )
      }
    >
      <FilesField
        label="Documents"
        value={files}
        readonly={payment.status !== "under-instruction"}
        onChange={onFilesChange}
      />
      <Spacer />
      <TextField
        label="Notes du bénéficiaire"
        value={receipt ? receipt.notes : null}
        readonly
        textArea
      />
      <ErrorToast error={changeFiles.error} />
    </Sections.Section>
  );
}

type IndicatorMarkersOnPeriodProps = {
  indicator: InstructorIndicator;
  period: string;
};

export function IndicatorMarkersOnPeriod(props: IndicatorMarkersOnPeriodProps) {
  const { indicator, period } = props;

  const goals = useMemo(
    () => getMarkers(indicator.goals, period),
    [indicator, period],
  );
  const actuals = useMemo(
    () => getMarkers(indicator.actuals, period),
    [indicator, period],
  );
  const goal: Marker | null = goals[0] || null;
  const actual: Marker | null = actuals[0] || null;

  const goalStr = goal ? Units[indicator.unit].display(goal.value) : null;
  const actualStr = actual ? Units[indicator.unit].display(actual.value) : null;

  const changes = useMemo(() => {
    const all = [...goals, ...actuals];
    const sorted = sortBy(all, (m) => new Date(m.createdAt).valueOf());
    const events = sorted.reduce(
      (acc, item, i) => {
        if (item.type === "goal") {
          acc.push({
            at: item.createdAt,
            goal: item.value,
            actual: i === 0 ? null : acc[acc.length - 1].actual,
          });
        } else {
          acc.push({
            at: item.createdAt,
            goal: i === 0 ? null : acc[acc.length - 1].goal,
            actual: item.value,
          });
        }
        return acc;
      },
      [] as Array<{ at: string; goal: number | null; actual: number | null }>,
    );
    return events;
  }, [goals, actuals]);

  let str: ReactNode;
  if (goalStr && actualStr)
    str = (
      <T>
        {actualStr}
        <T style="minor">/{goalStr}</T>
      </T>
    );
  else if (goal) str = str = <T>{goalStr}</T>;
  else if (actual) {
    str = `${Units[indicator.unit].display(actual.value)} / ?`;
  }

  return (
    <WithHelp
      title="Historique"
      text={
        <Fragment>
          <Spacer />
          <Table>
            <thead>
              <tr>
                <th>Validé par le bénéficiaire le</th>
                <th>Objectif</th>
                <th>Réalisé</th>
              </tr>
            </thead>
            <tbody>
              {changes.map((c, i) => (
                <tr key={i}>
                  <td>{dayjs(c.at).format("LL")}</td>
                  <td>
                    {c.goal ? Units[indicator.unit].display(c.goal) : "-"}
                  </td>
                  <td>
                    {c.actual ? Units[indicator.unit].display(c.actual) : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Fragment>
      }
      display="mouseover"
    >
      {str}
    </WithHelp>
  );
}
