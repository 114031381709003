import { enums } from "superstruct";

enum Role {
  Benficiary = "benficiary",
  Instructor = "instructor",
  Level1 = "controller",
  Level2 = "financier",
  Executor = "payer",
  Manager = "iam-manager",
}

export const RoleValues = [
  Role.Benficiary,
  Role.Instructor,
  Role.Level1,
  Role.Level2,
  Role.Executor,
  Role.Manager,
];

export const RoleLabels: Record<Role, string> = {
  benficiary: "Bénéficiaire",
  instructor: "Instructeur",
  controller: "Validation Département niveau 1",
  financier: "Validation Département niveau 2",
  payer: "Chargé de mise en paiement",
  "iam-manager": "Gestionnaire des accès",
};

export const RoleSchema = enums(Object.values(Role));

export default Role;
