import { enums } from "superstruct";

export enum InstructorPaymentStatus {
  Configurable = "configurable", // Le paiement peut être configuré (montant, date du CTS)
  WaitingForDeclaration = "waiting-for-documents", // Les documents sont attendus de la part de l'utilisateur
  UnderInstruction = "under-instruction", // L'instruction est en cours
  UnderCertification = "under-level-1-validation", // La validation de niveau 1 est en cours
  UnderValidation = "under-level-2-validation", // La validation de niveau 2 est en cours
  Paid = "paid", // Le paiement est terminé
}

export default InstructorPaymentStatus;

export const InstructorPaymentStatusSchema = enums(
  Object.values(InstructorPaymentStatus),
);

export function isCertified(s: InstructorPaymentStatus) {
  return s === "paid";
}

export function isInstructed(s: InstructorPaymentStatus) {
  return (
    s === "under-level-1-validation" ||
    s === "under-level-2-validation" ||
    s === "paid"
  );
}

export function isRequested(s: InstructorPaymentStatus) {
  return (
    s === "under-instruction" ||
    s === "under-level-1-validation" ||
    s === "under-level-2-validation" ||
    s === "paid"
  );
}
