import { css } from "@emotion/react";
import {
  PropsWithChildren,
  RefObject,
  createContext,
  useContext,
  useRef,
} from "react";
import { rgba } from "polished";
import Theme from "../../Theme/client";
import FieldProps from "../FieldProps";
import { FieldValidity } from "../utilities";
import HeadlessFieldsContext from "./HeadlessFieldsContext";
import T from "@hpo/client/components/text/Text";
import Spinner from "@hpo/client/components/Spinner";

type FieldWrapperProps = PropsWithChildren<FieldProps> & {
  validity?: FieldValidity;
};

export default function FieldWrapper(props: FieldWrapperProps) {
  const { id, label, help, children, validity, readonly, disabled } = props;

  const headlessContext = useContext(HeadlessFieldsContext);
  const isHeadless =
    props.headless === undefined ? headlessContext : props.headless;

  const display: FieldDisplay = readonly
    ? "readonly"
    : disabled
      ? "disabled"
      : "normal";

  const ref = useRef<HTMLDivElement>(null);

  const containerStyle = css({
    display: "flex",
    flexDirection: "column",
  });

  const labelWrapperStyle = css({
    display: "flex",
    alignItems: "center",
  });

  const labelStyle = css({
    color: colorPerDisplay[display],
    flex: 1,
    alignItems: "center",
    whiteSpace: "nowrap",
    minWidth: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
  });

  const spinnerStyle = css({
    opacity: props.loading ? 1 : 0,
    transition: "all 150ms",
  });

  const inputStyle = css({
    borderWidth: isHeadless ? 0 : 1,
    borderColor: borderColorPerDisplay[display],
    borderStyle: "solid",
    background: backgroundPerDisplay[display],
    borderRadius: isHeadless ? 6 : 6,
  });

  if (isHeadless) {
    return <div css={inputStyle}>{children}</div>;
  }

  return (
    <FieldWrapperElementContext.Provider value={ref}>
      <div css={containerStyle} ref={ref}>
        <div css={labelWrapperStyle}>
          <label htmlFor={id} css={labelStyle}>
            <T>{label}</T>
          </label>
          {props.loading !== undefined ? (
            <div css={spinnerStyle}>
              <Spinner color={Theme.color} />
            </div>
          ) : null}
        </div>
        <div css={inputStyle}>{children}</div>
        {help ? (
          <T style="minor" color={Theme.text.fade} id={`${id}-help`}>
            {help}
          </T>
        ) : null}
        {validity && !validity.valid ? (
          <T style="minor" id={`${id}-validity`} color={Theme.error}>
            {validity.message}
          </T>
        ) : null}
      </div>
    </FieldWrapperElementContext.Provider>
  );
}

export const FieldWrapperElementContext = createContext<
  RefObject<HTMLDivElement>
>({
  current: null,
});

type FieldDisplay = "normal" | "disabled" | "readonly";

const colorPerDisplay: Record<FieldDisplay, string> = {
  normal: Theme.color,
  disabled: Theme.text.normal,
  readonly: rgba("black", 0.5),
};

const backgroundPerDisplay: Record<FieldDisplay, string> = {
  normal: rgba(Theme.color, 0.1),
  disabled: rgba(Theme.text.normal, 0.1),
  readonly: "white",
};

const borderColorPerDisplay: Record<FieldDisplay, string> = {
  normal: Theme.color,
  disabled: Theme.text.normal,
  readonly: "white",
};
