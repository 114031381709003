import { useState } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { Action } from "@hpo/client/models/Action";
import ActionType from "@hpo/client/utilities/enums/ActionType";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import { ProjectDraft } from "@hpo/client/models/Project";
import Toasting from "@hpo/client/components/Toasting";

/** @todo : Le composant accepte en entrée un objet (Action) et produit un id.
 * L'appelant a donc la responsabilité de lire côté serveur (pour passer un Action en prop)
 * mais pas d'écrire (car c'est ce composant qui s'en charge).
 * Donc les responsabilités sont diluées entre ce composant et son appelant
 * => Et ca, ca favorise pas la réutilsiation, c'est pas du code ultra propre
 *
 * 2 possibilités pour revoir ca :
 * - Soit ce composant prend toute la responsabilité de la relation serveur (lecture et écriture)
 * - Soit ce composant se dégage de la relation serveur (et du coup onDone devient (d : ActionCreation) => unknown ) : Arch smart/dumb components https://celineung.github.io/blog/distinguer-composants-smart-et-dumb */

/** @valentin-consulting OK je comprends, le problème ici c'est que je récupère l'action depuis project.actions
 * et donc depuis le project qui est chargé bien en amont. Donc pour respecter le principe de responsabilité unique
 * il faudrait à la limite que je crée un contexte qui charge le projet et modifie tout ses sous-éléments (actions, dépenses, ...).
 * Tu en penses quoi ? Ca me semble être une grosse modification non ? */

/** @gui : Créer un service pour ça ? */
type ActionSideBarProps = {
  project: ProjectDraft;
  action: Action | null;
  actionType: ActionType;
  visible: boolean;
  onDone: (newAction: string | null) => unknown;
};

export default function ActionSideBar(props: ActionSideBarProps) {
  const { project, action, actionType, visible, onDone } = props;
  const server = useServerSdk();

  const [label, setLabel] = useState<string | null>(action && action.label);

  const [goal, setGoal] = useState<string | null>(action && action.goal);

  const [cost, setCost] = useState<number | null>(action && action.cost);

  const [means, setMeans] = useState<string | null>(action && action.means);

  const [planning, setPlanning] = useState<string | null>(
    action && action.planning,
  );

  const [saveAction, savingStatus] = useSubmitCallback(async () => {
    if (!label || !goal || cost === null || !means || !planning) {
      throw new MessageException("Veuillez remplir tous les champs", null);
    }

    if (action) {
      await server.updateAction(project.id, action.id, {
        type: actionType,
        label,
        goal,
        cost,
        means,
        planning,
      });
      Toasting.success("Succès", "Action mise à jour");
      onDone(action.id);
    } else {
      const action = await server.createAction(project.id, {
        type: actionType,
        label,
        goal,
        cost,
        means,
        planning,
      });
      Toasting.success("Succès", "Action créée");
      onDone(action);
    }
  }, [label, goal, cost, means, planning, action]);

  return (
    <SideBar
      visible={visible}
      onHide={() => onDone(null)}
      header={
        <TextTitleMinor>
          {action
            ? action.label
            : actionType === "communication"
              ? "Nouvelle communication"
              : "Nouveau transfert"}
        </TextTitleMinor>
      }
    >
      <ErrorToast error={savingStatus.error} />
      <TextField
        required
        id="label"
        label="Intitulé"
        value={label}
        onChange={setLabel}
      />
      <Spacer />
      <TextField
        required
        id="goal"
        label="Objectif"
        value={goal}
        onChange={setGoal}
      />
      <Spacer />
      <NumberField
        id="cost"
        value={cost}
        onChange={setCost}
        label="Coût"
        unit="euro"
        allowZero
      />
      <Spacer />
      <TextField
        required
        id="means"
        label="Moyens"
        value={means}
        onChange={setMeans}
      />
      <Spacer />
      <TextField
        required
        id="planning"
        label="Planning"
        value={planning}
        onChange={setPlanning}
      />
      <Spacer scale={2} />
      <Button
        onClick={saveAction}
        label="Valider"
        loading={savingStatus.running}
      />
    </SideBar>
  );
}
