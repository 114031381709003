import Badge from "./Badge";
import { BeneficiaryPaymentStatus } from "./enums/BeneficiaryPaymentStatus";
import Theme from "./Theme/client";

type BeneficiaryPaymentStatusBadgeProps = {
  status: BeneficiaryPaymentStatus;
};

export default function BeneficiaryPaymentStatusBadge(
  props: BeneficiaryPaymentStatusBadgeProps,
) {
  const { status } = props;
  const label = BeneficiaryPaymentStatusLabels[status];
  const color = BeneficiaryPaymentStatusColors[status];
  return <Badge color={color}>{label}</Badge>;
}

export const BeneficiaryPaymentStatusLabels: Record<
  BeneficiaryPaymentStatus,
  string
> = {
  locked: "A venir",
  available: "En attente des documents",
  "under-analyse": "En cours d'instruction",
  ended: "Effectué",
};

export const BeneficiaryPaymentStatusColors: Record<
  BeneficiaryPaymentStatus,
  string
> = {
  locked: "grey",
  available: "#c89400",
  "under-analyse": Theme.color,
  ended: "grey",
};
