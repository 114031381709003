import React, { Fragment, PropsWithChildren, ReactNode, useMemo } from "react";
import { css } from "@emotion/react";
import { rgba } from "polished";
import { flatten } from "lodash";
import Icon, { IconName, IconProps } from "../components/Icon";
import Button from "../components/Button";
import Clickable from "../components/Clickable";
import Spacer from "./Spacer";
import Theme from "./Theme/client";
import EmptyView from "./EmptyView";
import T from "@hpo/client/components/text/Text";
import Intersperse from "@hpo/client/components/Intersperse";
import Divider from "@hpo/client/components/Divider";

type ListProps<I> = {
  noFrame?: boolean;
  data: Array<I>;
  renderItem: (i: I, index: number) => ReactNode;
  renderEmpty?: () => ReactNode;
};

export default function List<I>(props: ListProps<I>) {
  const content =
    props.data.length === 0 ? (
      props.renderEmpty ? (
        props.renderEmpty()
      ) : (
        <EmptyView message="Aucun élément" />
      )
    ) : (
      <Intersperse between={() => <Divider />}>
        {props.data.map((i, n) => (
          <Fragment key={n}>{props.renderItem(i, n)}</Fragment>
        ))}
      </Intersperse>
    );
  if (props.noFrame) return content;
  else return <ListFrame>{content}</ListFrame>;
}

export function ListFrame(props: PropsWithChildren) {
  let containerCss = css({
    padding: 0,
    margin: 0,
    listStyleType: "none",
  });

  containerCss = css(containerCss, {
    border: `1px solid ${rgba("black", 0.1)}`,
    borderRadius: 6,
    background: "white",
  });

  return <div css={containerCss}>{props.children}</div>;
}

export type ListItemProps = {
  label: string;
  oneLineLabel?: boolean;
  intro?: string;
  help?: string | Array<string | null | undefined>;
  icon?: IconName | IconProps;
  onClick?: () => void;
  to?: string;
  right?: ReactNode;
  onDelete?: () => void;
  disabled?: boolean;
};

export function ListItem(props: ListItemProps) {
  const { intro, label, icon, oneLineLabel, help, onDelete } = props;

  let containerCss = css({
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "all 100ms",
    width: "100%",
  });

  if (Clickable.isReactive(props)) {
    containerCss = css(containerCss, {
      "&:hover": {
        background: rgba(Theme.color, 0.1),
        cursor: "pointer",
      },
    });
  }

  if (props.disabled) {
    containerCss = css(containerCss, {
      background: rgba("black", 0.05),
      opacity: 0.7,
    });
  }

  const helps = useMemo(
    () => flatten([help]).filter((h) => !!h) as Array<string>,
    [help],
  );

  return (
    <Clickable {...props} css={containerCss}>
      <Intersperse between={() => <Spacer horizontal scale={0.5} />}>
        {icon ? (
          typeof icon === "string" ? (
            <Icon name={icon} />
          ) : (
            <Icon {...icon} />
          )
        ) : null}
        <div css={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
          {intro ? (
            <T oneLine={oneLineLabel} style="minor" opacity={0.7} tag="div">
              {intro}
            </T>
          ) : null}
          <T oneLine={oneLineLabel}>{label}</T>
          {helps.map((h, i) => (
            <T style="minor" key={i} opacity={0.7} tag="div">
              {h}
            </T>
          ))}
        </div>
        {props.right}
        {onDelete ? (
          <Button icon="trash" onClick={onDelete} style="discreet" />
        ) : null}
      </Intersperse>
    </Clickable>
  );
}
