export type CommitmentDescriptor = {
  id: string;
  label: string;
};

const Commitments: CommitmentDescriptor[] = [
  {
    id: "exactitude",
    label:
      "Le porteur de projet atteste de l’exactitude des renseignements fournis dans la présente demande et ses annexes",
  },
  {
    id: "financier",
    label:
      "Le porteur de projet atteste ne pas être en difficulté financière au sens des lignes directrices des régimes cadres nationaux mentionnés dans le règlement de consultation.",
  },
  {
    id: "procedure",
    label:
      "Le porteur de projet atteste ne pas être en procédure de remboursement d'une aide publique déjà octroyée, incompatible avec le marché intérieur, au sens des lignes directrices des régimes cadres nationaux mentionnés dans le règlement de consultation.",
  },
  {
    id: "depot",
    label:
      "Le porteur de projet atteste avoir pris connaissance que le seul dépôt de la présente demande ne vaut pas attribution d’une aide départementale.",
  },
  {
    id: "informer",
    label:
      "Le porteur de projet s'engage à informer le service instructeur 3A Conseil, AMO du Département de toute évolution de sa situation.",
  },
  {
    id: "controle",
    label:
      "Le porteur de projet s'engage à se soumettre aux procédures de contrôle et d’évaluation qui seront réalisées par  le service instructeur 3A Conseil, AMO du Département , le cas échéant de l’attribution d’une aide départementale.",
  },
  {
    id: "documents",
    label:
      "Le porteur de projet s'engage à présenter au service instructeur 3A Conseil, AMO du Département, toutes pièces ou documents nécessaires au contrôle de la mise en œuvre de la présente aide. En cas de défaut de mobilisation de l'aide, le bénéficiaire devra s'acquitter des sommes indues sous le délai maximum indiqué par le Payeur Départemental.",
  },
  {
    id: "mention",
    label:
      "Le porteur de projet s'engage à mentionner l’intervention du Département de La Réunion dans toutes les phases de publicité ou d’information qui seront conduites en lien avec les actions financées.",
  },
];

export function getCommitments() {
  return [...Commitments];
}

export default Commitments;
