import { Redirect } from "wouter";
import { Fragment } from "react";
import { useMe, useWhoAmI } from "../RootNavigator/services/WhoAmI";
import ErrorView from "@hpo/client/utilities/ErrorView";
import {
  conventionsRoute,
  myPaymentsRoute,
  paymentsRoute,
  projectsRoute,
  managementRoute,
} from "@hpo/client/utilities/routes";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";
import T from "@hpo/client/components/text/Text";
import Card from "@hpo/client/components/Card";
import Columns from "@hpo/client/utilities/Columns";
import Spacer from "@hpo/client/utilities/Spacer";
import Button from "@hpo/client/components/Button";
import Role from "@hpo/client/utilities/enums/Role";

export default function WelcomeScreen() {
  const whoAmI = useWhoAmI();
  const user = whoAmI.useUser();

  const me = useMe();

  return (
    <Page>
      <PageHeader title={`${me.firstName}, bienvenue sur la plateforme HPO`} />
      <Spacer />
      <Columns columns={2}>
        {me.roles.includes(Role.Benficiary) ? (
          <Card>
            <T style="subtitle">Vos subventions</T>
            <Spacer scale={0.5} />
            <T>
              Transmettez les documents nécéssaires à l'instruction de vos
              demandes de subvention
            </T>
            <Spacer />
            <Button to={myPaymentsRoute.getRootUrl()} label="Accéder" />
          </Card>
        ) : null}
        {me.roles.includes(Role.Instructor) ||
        me.roles.includes(Role.Level1) ||
        me.roles.includes(Role.Level2) ||
        me.roles.includes(Role.Executor) ? (
          <Fragment>
            <Card>
              <T style="subtitle">Paiements à instruire</T>
              <Spacer scale={0.5} />
              <T>
                Visualisez d'un coup d'oeil les paiements sur lesquels votre
                validation est attendue
              </T>
              <Spacer />
              <Button
                to={paymentsRoute.getRootUrl()}
                label="Paiements à instruire"
              />
            </Card>
          </Fragment>
        ) : null}
        {me.roles.includes(Role.Instructor) ? (
          <Fragment>
            <Card>
              <T style="subtitle">Gérer les projets et les conventions</T>
              <Spacer scale={0.5} />
              <T>Instruisez les projets, et gérez les conventions</T>
              <Spacer />
              <Button to={projectsRoute.getRootUrl()} label="Projets" />
              <Spacer horizontal />
              <Button to={conventionsRoute.getRootUrl()} label="Conventions" />
            </Card>
          </Fragment>
        ) : null}
        {me.roles.includes(Role.Manager) ? (
          <Fragment>
            <Card>
              <T style="subtitle">
                Gérer les utilisateurs et les bénéficiaires
              </T>
              <Spacer scale={0.5} />
              <T>
                Gérez les utilisateurs, leur accès à la plateforme et les
                bénéficiaires enregistrés
              </T>
              <Spacer />
              <Button
                to={managementRoute.getRootUrl()}
                label="Gestion de la plateforme"
              />
            </Card>
          </Fragment>
        ) : null}
      </Columns>
    </Page>
  );

  return user.roles.includes(Role.Instructor) ? (
    <Redirect to={projectsRoute.getUrl()} />
  ) : user.roles.includes(Role.Benficiary) ? (
    <Redirect to={myPaymentsRoute.getUrl()} />
  ) : (
    <ErrorView error="Rôle non encore géré pour le moment" />
  );
}
