import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { sumBy, uniq } from "lodash";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { useAssistance } from "../../RootNavigator/services/Assistance";
import PaymentWarnings, {
  getPaymentWarnings,
} from "../PaymentScreen/PaymentWarnings";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import { HelpIcon } from "../../RootNavigator/assistance/Help/HelpIcon";
import FoundationTable from "./FoundationTable";
import InstructorPaymentStatusBadge from "@hpo/client/utilities/InstructorPaymentStatusBadge";
import T from "@hpo/client/components/text/Text";
import Await from "@hpo/client/utilities/Await";
import Columns from "@hpo/client/utilities/Columns";
import List, { ListItem } from "@hpo/client/utilities/List";
import Sections from "@hpo/client/utilities/Sections";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";
import DateField from "@hpo/client/utilities/fields/DateField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import {
  conventionPaymentRoute,
  conventionRoute,
  getUrl,
  projectRoute,
} from "@hpo/client/utilities/routes";
import useData from "@hpo/client/utilities/useData";
import {
  getMostRevealantAmount,
  InstructorPaymentAmounts,
} from "@hpo/client/models/InstructorPayment";
import { PaymentFragmentLabels } from "@hpo/client/utilities/enums/PaymentFragment";
import { ClickableProps } from "@hpo/client/components/Clickable";
import { ConventionDetails } from "@hpo/client/models/Convention";
import smoothJoin from "@hpo/client/utilities/smoothJoin";
import FilesField from "@hpo/client/utilities/fields/FilesField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Toasting from "@hpo/client/components/Toasting";
import Theme from "@hpo/client/utilities/Theme/client";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";
import { useRouteParam } from "@hpo/client/utilities/Navigation";
import Card from "@hpo/client/components/Card";
import Button from "@hpo/client/components/Button";
import NumberField from "@hpo/client/utilities/fields/NumberField";

export default function ConventionScreen() {
  const server = useServerSdk();

  const id = useRouteParam(conventionRoute, "conventionId");

  const [convention] = useData(() => server.getConvention(id), [id]);

  return (
    <Await promise={convention}>
      {(c) => <ConventionView convention={c} />}
    </Await>
  );
}

type ConventionViewProps = {
  convention: ConventionDetails;
};

function ConventionView(props: ConventionViewProps) {
  const c = props.convention;
  const server = useServerSdk();
  const periods = useMemo(() => uniq(c.payments.map((p) => p.period)), []);

  const assistance = useAssistance();
  assistance.useChatWith(c.organizations);

  const [amendments, setAmendments] = useState<Array<string> | null>(
    c.amendments,
  );

  const [onAmendmentsChange, amendmentsSave] = useSubmitCallback(
    async (amendments: Array<string> | null) => {
      setAmendments(amendments);
      await server.updateConvention(c.id, { amendments: amendments });
      Toasting.success(
        "C'est fait",
        "Les avenants de la convention sont enregistrés.",
      );
    },
    [c.id],
  );

  const [iban, setIban] = useState<Array<string> | null>(c.iban);

  const [onIbanChange, ibanSave] = useSubmitCallback(
    async (iban: Array<string> | null) => {
      setIban(iban);
      await server.updateConvention(c.id, { iban: iban });
      Toasting.success("C'est fait", "L'IBAN de la convention est enregistré.");
    },
    [c.id],
  );

  return (
    <Page>
      <WithHelp
        title="Cette convention fait l'objet d'avenant(s)"
        text="Le montant des paiements peut avoir fait l'objet de modification. Soyez vigilants."
        color={Theme.warning}
        inactive={!amendments}
      >
        <PageHeader title={`Convention n°${c.reference}`} />
      </WithHelp>
      <TextField
        value={smoothJoin(c.organizations.map((o) => o.businessName))}
        label="Bénéficiaires"
        readonly
      />
      <Spacer />
      <Columns columns={3}>
        <TextField
          value={c.reference}
          label="Numéro de la convention"
          readonly
        />
        <DateField value={c.date} label="Date de la convention" readonly />
        <NumberField
          value={sumBy(c.fundings, (f) => f.amount)}
          label={c.amendments ? "Montant initial" : "Montant"}
          readonly
          unit="euro"
        />
      </Columns>
      <Spacer />
      <T style="subtitle">Projets</T>
      <Spacer />
      <List
        data={c.fundings}
        renderItem={(f) => (
          <ListItem
            label={getProjectLabel(f.project)}
            to={getUrl(projectRoute, { id: f.project.id })}
          />
        )}
      />
      <Spacer />
      <T style="subtitle">Paiements</T>
      <Sections max={Infinity}>
        {periods.map((period) => (
          <PeriodSection period={period} convention={c} key={period} />
        ))}
      </Sections>
      <Spacer />
      <T style="subtitle">Documents</T>
      <FilesField
        value={c.files}
        readonly
        label="Convention"
        viewerHeight={300}
      />
      <Spacer />
      <FilesField
        value={amendments}
        onChange={onAmendmentsChange}
        label="Avenants"
        viewerHeight={300}
        loading={amendmentsSave.running}
      />
      <ErrorToast error={amendmentsSave.error} />
      <Spacer />
      <FilesField
        value={iban}
        onChange={onIbanChange}
        label="IBAN"
        viewerHeight={300}
        loading={ibanSave.running}
      />
      <ErrorToast error={ibanSave.error} />
    </Page>
  );
}

type PeriodSectionProps = {
  convention: ConventionDetails;
  period: string;
};

function PeriodSection(props: PeriodSectionProps) {
  const { period, convention } = props;

  const [tableVisible, setTableVisible] = useState(false);

  return (
    <Sections.Section
      title={period}
      id={period}
      opened={dayjs().year() >= parseInt(period)}
      right={
        <Button
          icon="table"
          style={tableVisible ? "normal" : "discreet"}
          onClick={() => setTableVisible(!tableVisible)}
        />
      }
    >
      <Columns columns={3}>
        {convention.payments
          .filter((payment) => payment.period === period)
          .map((payment) => (
            <PaymentCard
              key={payment.id}
              payment={payment}
              to={conventionPaymentRoute.getRootUrl({
                conventionId: convention.id,
                paymentId: payment.id,
              })}
            />
          ))}
      </Columns>
      <Spacer />
      {tableVisible ? (
        <FoundationTable foundation={convention.foundations[period]} />
      ) : null}
    </Sections.Section>
  );
}

type PaymentCardProps = {
  payment: InstructorPaymentAmounts;
} & ClickableProps;

function PaymentCard(props: PaymentCardProps) {
  const { payment, ...clickable } = props;

  const { amount, label, info } = getMostRevealantAmount(payment);

  const warningPoints = useMemo(() => getPaymentWarnings(payment), [payment]);

  return (
    <Card title={PaymentFragmentLabels[payment.fragment]} {...clickable}>
      <Spacer />
      <T style="minor" align="right" display="block">
        {label} <HelpIcon title={label}>{info}</HelpIcon>{" "}
        {warningPoints.length ? (
          <HelpIcon
            title={"Points de vigilance"}
            icon="error"
            color={Theme.warning}
          >
            <PaymentWarnings payment={payment} />
          </HelpIcon>
        ) : null}
      </T>
      <T style="body" align="right" display="block">
        {Units.euro.display(amount)}
      </T>
      <Spacer />
      <InstructorPaymentStatusBadge status={payment.status} />
    </Card>
  );
}
