import { enums } from "superstruct";

enum ActionType {
  Communication = "communication",
  Transfer = "transfer",
}

export const ActionTypeValues = [
  ActionType.Communication,
  ActionType.Transfer,
] as const;

export const ActionTypeSchema = enums(ActionTypeValues);

export default ActionType;
