import { Fragment, useMemo, useState } from "react";
import ProjectInquirySideMenu from "./ProjectInquirySideMenu";
import Button from "@hpo/client/components/Button";
import { IconProps } from "@hpo/client/components/Icon";
import { Analysis } from "@hpo/client/models/Analysis";
import Theme from "@hpo/client/utilities/Theme/client";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";

export type ProjectInquiryStatusProps = {
  analysis: Analysis;
  readonly: boolean;
  onChange: (v: Analysis) => unknown;
};

export default function ProjectInquiryStatus(props: ProjectInquiryStatusProps) {
  const [menuVisible, setMenuVisible] = useState(false);
  const { analysis, readonly } = props;

  const [onChange, change] = useSubmitCallback(
    async (analysis: Analysis) => {
      console.log(readonly);
      if (readonly) return;
      setMenuVisible(false);
      await props.onChange(analysis);
    },
    [props.onChange],
  );

  const icon = useMemo<IconProps>(() => {
    if (analysis.skippable) {
      return { name: "fast-forward" };
    } else {
      if (analysis.decision === null) {
        return { name: "hammer" };
      } else if (analysis.decision === true) {
        return { name: "thumbs-up" };
      } else {
        return { name: "thumbs-down", color: Theme.error };
      }
    }
  }, [analysis]);

  const label = useMemo(() => {
    if (analysis.comment) {
      if (analysis.comment.length > 20) {
        return analysis.comment.substring(0, 20) + "...";
      } else {
        return " " + analysis.comment;
      }
    }
    return "";
  }, [analysis]);

  return (
    <Fragment>
      <ProjectInquirySideMenu
        visible={menuVisible}
        onHide={() => setMenuVisible(false)}
        analysis={analysis}
        onChange={onChange}
        readonly={readonly}
      />
      <Button
        aria-label="Commentaires"
        onClick={(e) => {
          if (e.shiftKey)
            void onChange({ ...analysis, decision: true, skippable: false });
          else setMenuVisible(true);
        }}
        icon={icon}
        label={label}
        loading={change.running}
      />
    </Fragment>
  );
}
