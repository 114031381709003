import Program from "../models/Program";
import ActionType from "./enums/ActionType";
import AnnexType from "./enums/AnnexType";
import ExpenseType from "./enums/ExpenseType";
import IndicatorType from "./enums/IndicatorType";
import ProgramKey from "./enums/ProgramKey";
import { getAttachementDescriptorsByProjectType } from "./project/Attachments";
import { getCommitments } from "./project/Commitments";
import { getCompliancesByProjectType } from "./project/Compliances";
import { getDescriptionsByProjectType } from "./project/ProjectDescriptions";

const Programs: Record<ProgramKey, Program> = {
  [ProgramKey.LutteCollective]: {
    key: ProgramKey.LutteCollective,
    short: "Lutte collective",
    label:
      "Aides en faveur de la lutte collective et de la protection sanitaire des filières animales et végétales",
    annexes: [AnnexType.A2Product, AnnexType.A3, AnnexType.A4, AnnexType.A5],
    descriptions: [],
    compliances: [],
    commitments: [],
    attachments: [],
    indicatorTypes: [],
    expenseTypes: [],
    actionTypes: [],
  },
  [ProgramKey.EchangeConnaissance]: {
    key: ProgramKey.EchangeConnaissance,
    short: "Echange de connaissances",
    label:
      "Aides à l’échange de connaissances et aux actions d’information pour les filières identitaires et émergentes",
    annexes: [AnnexType.A2Salary, AnnexType.A3, AnnexType.A4, AnnexType.A5],
    descriptions: [],
    compliances: [],
    commitments: [],
    attachments: [],
    indicatorTypes: [],
    expenseTypes: [],
    actionTypes: [],
  },
  [ProgramKey.PromotionProduits]: {
    key: ProgramKey.PromotionProduits,
    short: "Promotion des produits",
    label:
      "Aides aux actions de promotion en faveur des produits agricoles pour les filières identitaires et émergentes",
    annexes: [AnnexType.A2Service, AnnexType.A3, AnnexType.A4, AnnexType.A5],
    descriptions: [],
    compliances: [],
    commitments: [],
    attachments: [],
    indicatorTypes: [],
    expenseTypes: [],
    actionTypes: [],
  },
  [ProgramKey.Animation]: {
    key: ProgramKey.Animation,
    short: "Animation de filière",
    label: "Aides à l’animation dans le secteur agricole",
    annexes: [
      AnnexType.A2Salary,
      AnnexType.A2Service,
      AnnexType.A3,
      AnnexType.A4,
      AnnexType.A5,
    ],
    descriptions: [],
    compliances: [],
    commitments: [],
    attachments: [],
    indicatorTypes: [],
    expenseTypes: [],
    actionTypes: [],
  },
  [ProgramKey.AnimationChambre]: {
    key: ProgramKey.AnimationChambre,
    short: "Animation par la chambre",
    label: "Aides à l’animation dans le secteur agricole (chambre)",
    annexes: [
      AnnexType.A2Salary,
      AnnexType.A2Service,
      AnnexType.A3,
      AnnexType.A4,
      AnnexType.A5,
    ],
    descriptions: [],
    compliances: [],
    commitments: [],
    attachments: [],
    indicatorTypes: [],
    expenseTypes: [],
    actionTypes: [],
  },
  [ProgramKey.LutteCollectiveAgroeco]: {
    key: ProgramKey.LutteCollectiveAgroeco,
    short: "Lutte agroécologique et PBI",
    label:
      "Aides en faveur de la lutte collective et de la protection sanitaire des filières animales et végétales par des méthodes de lutte agroécologiques et promotion de la Protection Biologique Intégrée",
    annexes: [
      AnnexType.A2Service,
      AnnexType.A2Product,
      AnnexType.A3,
      AnnexType.A4,
      AnnexType.A5,
    ],
    descriptions: [],
    compliances: [],
    commitments: [],
    attachments: [],
    indicatorTypes: [],
    expenseTypes: [],
    actionTypes: [],
  },
  [ProgramKey.SystemesQualite]: {
    key: ProgramKey.SystemesQualite,
    short: "Participation à la qualité",
    label:
      "Aides en faveur de la particpation des producteurs de produits agricoles à des systèmes de qualité",
    annexes: [
      AnnexType.A2Service,
      AnnexType.A2Product,
      AnnexType.A3,
      AnnexType.A4,
    ],
    descriptions: [],
    compliances: [],
    commitments: [],
    attachments: [],
    indicatorTypes: [],
    expenseTypes: [],
    actionTypes: [],
  },
};

function getIndicatorTypesByAnnexes(annexes: Array<AnnexType>) {
  const types: IndicatorType[] = [
    IndicatorType.Achievement,
    IndicatorType.Result,
  ];
  if (annexes.find((annex) => annex === AnnexType.A4)) {
    types.push(IndicatorType.Communication);
  }
  if (annexes.find((annex) => annex === AnnexType.A5)) {
    types.push(IndicatorType.Transfert);
  }
  return types;
}

function getExpenseTypesByAnnexes(annexes: Array<AnnexType>) {
  return annexes.reduce((acc, annex) => {
    if (annex === AnnexType.A2Product) acc.push(ExpenseType.Product);
    if (annex === AnnexType.A2Salary) acc.push(ExpenseType.Salary);
    if (annex === AnnexType.A2Service) acc.push(ExpenseType.Service);
    return acc;
  }, [] as Array<ExpenseType>);
}

function getActionTypesByAnnexes(annexes: Array<AnnexType>) {
  return annexes.reduce((acc, annex) => {
    if (annex === AnnexType.A4) acc.push(ActionType.Communication);
    if (annex === AnnexType.A5) acc.push(ActionType.Transfer);
    return acc;
  }, [] as Array<ActionType>);
}

Object.entries(Programs).forEach(([key, program]) => {
  const k = key as ProgramKey;
  program.descriptions = getDescriptionsByProjectType(k);
  program.compliances = getCompliancesByProjectType(k);
  program.commitments = getCommitments();
  program.attachments = getAttachementDescriptorsByProjectType(k);
  program.indicatorTypes = getIndicatorTypesByAnnexes(program.annexes);
  program.expenseTypes = getExpenseTypesByAnnexes(program.annexes);
  program.actionTypes = getActionTypesByAnnexes(program.annexes);
});

export default Programs;
