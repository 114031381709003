import { enums } from "superstruct";

export enum ProjectStatus {
  Draft = "draft",
  UnderInstruction = "under-instruction",
  Done = "done",
}

export const ProjectStatusSchema = enums(Object.values(ProjectStatus));

export default ProjectStatus;
