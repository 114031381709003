import ProgramKey from "../enums/ProgramKey";

export type DescriptionDescriptor = {
  key: string;
  projectType: ProgramKey;
  label: string;
  help?: string;
};

export function getDescriptionsByProjectType(projectType: ProgramKey) {
  return ProjectDescriptions.filter((a) => a.projectType === projectType);
}

export function getDescriptionByKey(key: string) {
  const descriptor = ProjectDescriptions.find((desc) => desc.key === key);
  if (!descriptor) throw new Error("Description descriptor not found");
  return descriptor;
}

const animationDescriptions: DescriptionDescriptor[] = [
  {
    key: "animation_objectifs_chiffres",
    projectType: ProgramKey.Animation,
    label: "Objectifs chiffrés",
    help: "Présenter ici les objectifs stratégiques chiffrés du porteur de projet",
  },
  {
    key: "animation_programme_actions_financer",
    projectType: ProgramKey.Animation,
    label: "Descriptif détaillé du programme d'actions à financer",
    help: "Justifier ici comment les actions à financer vont permettre d’atteindre les objectifs avec une vision annuelle des performances",
  },
  {
    key: "animation_expertise_porteur_projet",
    projectType: ProgramKey.Animation,
    label: "Expériences du porteur de projet dans le domaine",
    help: "Décrire ici les expériences du porteur de projet dans le domaine",
  },
  {
    key: "animation_note_identitaire",
    projectType: ProgramKey.Animation,
    label:
      "Note sur le caractère identitaire ou émergent de la filière ou du territoire ou sur le caractère de forte concurrence par les produits agricoles d’importation de la filière représentée (que pour Axe 1)",
    help: "Justifier ici en quoi la filière agricole ou le territoire est identitaire ou émergent ou soumis à une forte concurrence des produits agricoles d’importation.",
  },
  {
    key: "animation_note_capacite_candidat",
    projectType: ProgramKey.Animation,
    label: "Note sur la capacité du candidat à atteindre les objectifs",
    help: "Décrire ici les moyens humains et matériels mis en œuvre permettant de mener à bien le projet.",
  },
  {
    key: "animation_axe1_note_representation_partenaires",
    projectType: ProgramKey.Animation,
    label: "Note sur la représentativité des partenaires ( que pour Axe 1)",
    help: "Démontrer ici la représentativité du collectif de partenaires, à minima 50% de la production locale des produits agricoles visés des filières identitaires et émergentes ou fortement concurrencées par les produits agricoles d’importation. Présenter au moins 1 projet de convention de partenariat en démontrant la volonté de la structure de mettre en place un programme d'actions partagé et fédérateur.",
  },
  {
    key: "animation_axe2_note_benefice_secteur_agricole",
    projectType: ProgramKey.Animation,
    label: "Note sur le bénéfice au secteur agricole (que pour Axe 2)",
    help: "Démontrer ici la mise en place des actions d’animation territoriale bénéficiant au secteur agricole dans le cadre de politiques stratégiques agricoles et alimentaires.",
  },
];

const animationChambreDescriptions: DescriptionDescriptor[] = [
  {
    key: "animation_chambre_objectifs_chiffrés",
    projectType: ProgramKey.AnimationChambre,
    label: "Objectifs chiffrés",
    help: "Présenter ici les objectifs stratégiques chiffrés du porteur de projet.",
  },
  {
    key: "animation_chambre_programme_actions_financer",
    projectType: ProgramKey.AnimationChambre,
    label: "Descriptif détaillé du programme d'actions à financer",
    help: "Justifier ici comment les actions à financer vont permettre d’atteindre les objectifs avec une vision annuelle des performances.",
  },
  {
    key: "animation_chambre_expertise_porteur_projet",
    projectType: ProgramKey.AnimationChambre,
    label: "Expériences du porteur de projet dans le domaine",
    help: "Décrire ici les expériences du porteur de projet dans le domaine.",
  },
  {
    key: "animation_chambre_capacite_candidat",
    projectType: ProgramKey.AnimationChambre,
    label: "Note sur la capacité du candidat à atteindre les objectifs",
    help: "Décrire ici les moyens humains et matériels mis en œuvre permettant de mener à bien le projet.",
  },
  {
    key: "animation_chambre_representation_partenaires",
    projectType: ProgramKey.AnimationChambre,
    label: "Note sur la représentativité des partenaires",
    help: "Démontrer ici la représentativité du collectif de partenaires, à minima 50% de la production locale des produits agricoles visés. Présenter au moins 1 projet de convention de partenariat en démontrant la volonté de la structure de mettre en place un programme d'actions partagé et fédérateur.",
  },
  {
    key: "animation_chambre_benefice_secteur_agricole",
    projectType: ProgramKey.AnimationChambre,
    label: "Note sur le bénéfice au secteur agricole",
    help: "Démontrer ici la mise en place des actions d’animation territoriale bénéficiant au secteur agricole dans le cadre de politiques stratégiques agricoles et alimentaires.",
  },
];

const echangesConnaissancesDescriptions: DescriptionDescriptor[] = [
  {
    key: "echanges_connaissances_objectifs_chiffres",
    projectType: ProgramKey.EchangeConnaissance,
    label: "Objectifs chiffrés",
    help: "Présenter ici les objectifs stratégiques chiffrés du porteur de projet.",
  },
  {
    key: "echanges_connaissances_programme_actions_financer",
    projectType: ProgramKey.EchangeConnaissance,
    label: "Descriptif détaillé du programme d'actions à financer",
    help: "Justifier ici comment les actions à financer vont permettre d’atteindre les objectifs avec une vision annuelle des performances.",
  },
  {
    key: "echanges_connaissances_expertise_porteur_projet",
    projectType: ProgramKey.EchangeConnaissance,
    label: "Expériences du porteur de projet dans le domaine",
    help: "Décrire ici les expériences du porteur de projet dans le domaine.",
  },
  {
    key: "echanges_connaissances_note_identitaire",
    projectType: ProgramKey.EchangeConnaissance,
    label: "Note sur le caractère identitaire ou émergent de la filière",
    help: "Justifier ici en quoi la filière agricole est identitaire ou émergente.",
  },
  {
    key: "echanges_connaissances_note_capacite_candidat",
    projectType: ProgramKey.EchangeConnaissance,
    label: "Note sur la capacité du candidat à atteindre les objectifs",
    help: "Décrire ici les moyens humains et matériels mis en œuvre permettant de mener à bien le projet.",
  },
];

const lutteCollectiveDescriptions: DescriptionDescriptor[] = [
  {
    key: "lutte_collective_objectifs_chiffres",
    projectType: ProgramKey.LutteCollective,
    label: "Objectifs chiffrés",
    help: "Présenter ici les objectifs stratégiques chiffrés du porteur de projet",
  },
  {
    key: "lutte_collective_programme_actions_financer",
    projectType: ProgramKey.LutteCollective,
    label: "Descriptif détaillé du programme d'actions à financer",
    help: "Justifier ici comment les actions à financer vont permettre d’atteindre les objectifs avec une vision annuelle des performances.",
  },
  {
    key: "lutte_collective_expertise_porteur_projet",
    projectType: ProgramKey.LutteCollective,
    label:
      "Expériences du porteur de projet dans le domaine et pertinence du projet",
    help: "Décrire ici les expériences du porteur de projet dans le domaine et démontrer la plus-value apportée par les actions de lutte collective sur le niveau d’infestation et d’éradication.",
  },
  {
    key: "lutte_collective_lutte_sanitaire",
    projectType: ProgramKey.LutteCollective,
    label:
      "Note résumant le plan de lutte sanitaire collectif du porteur de projet",
    help: "Justifier ici en quoi les actions financées s'insèrent dans le plan de lutte sanitaire collectif du porteur de projet.",
  },
  {
    key: "lutte_collective_note_capacite_candidat",
    projectType: ProgramKey.LutteCollective,
    label: "Note sur la capacité du candidat à atteindre les objectifs",
    help: "Décrire ici les moyens humains et matériels mis en œuvre permettant de mener à bien les actions en matière de lutte sanitaire",
  },
  {
    key: "lutte_collective_note_respect_cadres_reglementaires",
    projectType: ProgramKey.LutteCollective,
    label:
      "Note sur le respect des cadres règlementaires nationaux et les perspectives sanitaires",
    help: "Démontrer ici le respect des cadres règlementaires nationaux en matière de lutte sanitaire collective et rassurer sur les perspectives sanitaires à long terme, après l’arrêt du financement par le Département.",
  },
];

const lutteCollectiveAgroEcoDescriptions: DescriptionDescriptor[] = [
  {
    key: "lutte_collective_agro_eco_objectifs_chiffres",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Objectifs chiffrés",
    help: "Présenter ici les objectifs stratégiques chiffrés du porteur de projet",
  },
  {
    key: "lutte_collective_agro_eco_programme_actions_financer",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Descriptif détaillé du programme d'actions à financer",
    help: "Justifier ici comment les actions à financer vont permettre d’atteindre les objectifs avec une vision annuelle des performances",
  },
  {
    key: "lutte_collective_agro_eco_expertise_porteur_projet",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Expériences du porteur de projet dans le domaine et pertinence du projet",
    help: "Décrire ici les expériences du porteur de projet dans le domaine et démontrer la plus-value apportée par les actions de lutte collective sur le niveau d’infestation et d’éradication ",
  },
  {
    key: "lutte_collective_agro_eco_lutte_sanitaire",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Note résumant le plan de lutte sanitaire collectif du porteur de projet",
    help: "Justifier ici en quoi les actions financées s'insèrent dans le plan de lutte sanitaire collectif du porteur de projet.",
  },
  {
    key: "lutte_collective_agro_eco_note_capacite_candidat",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Note sur la capacité du candidat à atteindre les objectifs",
    help: "Décrire ici les moyens humains et matériels mis en œuvre permettant de mener à bien les actions en matière de lutte sanitaire.",
  },
  {
    key: "lutte_collective_agro_eco_note_respect_cadres_reglementaires",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Note sur le respect des cadres règlementaires nationaux et les perspectives sanitaires",
    help: "Démontrer ici le respect des cadres règlementaires nationaux en matière de lutte sanitaire collective et rassurer sur les perspectives sanitaires à long terme, après l’arrêt du financement par le Département.",
  },
];

const promotionProduitsDescriptions: DescriptionDescriptor[] = [
  {
    key: "promotion_produits_objectifs_chiffres",
    projectType: ProgramKey.PromotionProduits,
    label: "Objectifs chiffrés",
    help: "Présenter ici les objectifs stratégiques chiffrés du porteur de projet",
  },
  {
    key: "promotion_produits_programme_actions_financer",
    projectType: ProgramKey.PromotionProduits,
    label: "Descriptif détaillé du programme d'actions à financer",
    help: "Justifier ici comment les actions à financer vont permettre d’atteindre les objectifs avec une vision annuelle des performances.",
  },
  {
    key: "promotion_produits_expertise_porteur_projet",
    projectType: ProgramKey.PromotionProduits,
    label: "Expériences du porteur de projet dans le domaine",
    help: "Décrire ici les expériences du porteur de projet dans le domaine.",
  },
  {
    key: "promotion_produits_note_identitaire",
    projectType: ProgramKey.PromotionProduits,
    label:
      "Note sur le caractère identitaire ou émergent de la filière représentée",
    help: "Justifier ici en quoi la filière agricole objet de la promotion est identitaire ou émergente.",
  },
  {
    key: "promotion_produits_note_capacite_candidat",
    projectType: ProgramKey.PromotionProduits,
    label: "Note sur la capacité du candidat à atteindre les objectifs",
    help: "Décrire ici les moyens humains et matériels mis en œuvre permettant de mener à bien le projet.",
  },
];

const systemesQualitéDescriptions: DescriptionDescriptor[] = [
  {
    key: "systemes_qualite_objectifs_chiffres",
    projectType: ProgramKey.SystemesQualite,
    label: "Objectifs chiffrés",
    help: "Présenter ici les objectifs stratégiques chiffrés du porteur de projet.",
  },
  {
    key: "systemes_qualite_programme_actions_financer",
    projectType: ProgramKey.SystemesQualite,
    label: "Descriptif détaillé du programme d'actions à financer",
    help: "Justifier ici comment les actions à financer vont permettre d’atteindre les objectifs avec une vision annuelle des performances.",
  },
  {
    key: "systemes_qualite_expertise_porteur_projet",
    projectType: ProgramKey.SystemesQualite,
    label: "Expériences du porteur de projet dans le domaine",
    help: "Décrire ici les expériences du porteur de projet dans le domaine.",
  },
  {
    key: "systemes_qualite_capacite_candidat",
    projectType: ProgramKey.SystemesQualite,
    label: "Note sur la capacité du candidat à atteindre les objectifs",
    help: "Décrire ici les moyens humains et matériels mis en œuvre permettant de mener à bien le projet.",
  },
  {
    key: "systemes_qualite_note_retombees_communication_grand_public",
    projectType: ProgramKey.SystemesQualite,
    label: "Note sur les résultats attendus",
    help: "Démontrer ici les retombées de la communication grand public sur la filière.",
  },
  {
    key: "systemes_qualite_note_retombees_communication_grand_public",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Une note justificative permettant de démontrer les retombées de la mise en place du système de qualité à long terme",
  },
];

const ProjectDescriptions = [
  ...animationDescriptions,
  ...animationChambreDescriptions,
  ...echangesConnaissancesDescriptions,
  ...lutteCollectiveDescriptions,
  ...lutteCollectiveAgroEcoDescriptions,
  ...promotionProduitsDescriptions,
  ...systemesQualitéDescriptions,
];

export default ProjectDescriptions;
