import { Fragment, useState } from "react";
import dayjs from "dayjs";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import WithHelp from "../RootNavigator/assistance/Help/WithHelp";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import useData from "@hpo/client/utilities/useData";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";
import Spacer from "@hpo/client/utilities/Spacer";
import Buttons from "@hpo/client/components/Buttons";
import Button from "@hpo/client/components/Button";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import Print, { Printed } from "@hpo/client/utilities/print/Print";
import PrintPreviewModal from "@hpo/client/utilities/print/PrintPreviewModal";
import {
  OverallStatistics,
  ProjectsPerformance,
  TagsPerformance,
} from "@hpo/client/models/Yearly";
import Units, { UnitName } from "@hpo/client/utilities/Units";
import MultiSelectField from "@hpo/client/utilities/fields/MultiSelectField";
import { Performance } from "@hpo/client/models/Performance";
import TextField from "@hpo/client/utilities/fields/TextField";
import { ProgramKeyValues } from "@hpo/client/utilities/enums/ProgramKey";
import smoothJoin from "@hpo/client/utilities/smoothJoin";
import Programs from "@hpo/client/utilities/Programs";
import DropDownField from "@hpo/client/utilities/fields/DropDownField";
import { IndicatorTypeLabels } from "@hpo/client/utilities/enums/IndicatorType";

export default function YearlyReportNavigator() {
  const server = useServerSdk();
  const [title, setTitle] = useState<string | null>("Rapport du programme HPO");
  const [overallPeriods, setOverallPeridos] = useState<Array<string>>([]);
  const [tagsPeriod, setTagsPeriod] = useState<string | null>(null);
  const [projectsPeriods, setProjectsPeriods] = useState<Array<string>>([]);
  const [options] = useData(() => server.getPeriods(), []);

  const [reportProps, setReportProps] = useState<Omit<
    YearlyReportExportProps,
    "onClose"
  > | null>(null);

  const [onSubmit] = useSubmitCallback(async () => {
    if (title === null) throw new MessageException("Titre manquant", null);
    const overall =
      overallPeriods.length === 0
        ? null
        : await server.getOverallStatistics(overallPeriods);
    const tags =
      tagsPeriod === null ? null : await server.getTagsPerformance(tagsPeriod);
    const projects =
      projectsPeriods === null
        ? null
        : await server.getProjectsPerformance(projectsPeriods);
    setReportProps({
      title,
      overallPeriods,
      tagsPeriod,
      projectsPeriods,
      overall,
      tags,
      projects,
    });
  }, [overallPeriods, tagsPeriod, projectsPeriods]);

  return (
    <Page>
      <PageHeader
        title="Rapport périodique"
        subtitle="Générez un rapport PDF reprenant les statistiques générales, les indicateurs d'impact et les indicateurs d'évaluation pour les périodes de votre choix"
      />
      <Spacer />
      {reportProps ? (
        <YearlyReportExport
          {...reportProps}
          onClose={() => setReportProps(null)}
        />
      ) : (
        <FieldsForm onSubmit={onSubmit}>
          <WithHelp
            display="mouseover"
            title="Statistiques générales"
            text="Affiche le nombre de structures et de projets financés, les montants conventionnés et versés, et le taux de consommation pour chaque appel à projet. Vous pouvez sélectionner les périodes pour lesquelles vous souhaitez obtenir ces statistiques"
          >
            <MultiSelectField
              label="Périodes à prendre en compte pour le calcul de statistiques générales"
              options={options}
              renderOption={(p) => ({ label: p })}
              keyExtractor={(p) => p}
              value={overallPeriods}
              onChange={setOverallPeridos}
            />
          </WithHelp>
          <Spacer />
          <WithHelp
            display="mouseover"
            title="Indicateurs d'impact"
            text="Affiche les indicateurs d'impact pour chaque étiquette d'indicateurs, ainsi qu'une synthèse par appel à projet. Vous pouvez sélectionner la période pour laquelle vous souhaitez obtenir ces indicateurs"
          >
            <DropDownField
              label="Période à prendre en compte pour les indicateurs d'impact"
              options={options}
              renderOption={(p) => ({ label: p })}
              keyExtractor={(p) => p}
              value={tagsPeriod}
              onChange={setTagsPeriod}
            />
          </WithHelp>
          <Spacer />
          <WithHelp
            display="mouseover"
            title="Indicateurs d'évaluation"
            text="Affiche les indicateurs d'évaluation pour chaque projet. Vous pouvez sélectionner les périodes pour laquelles vous souhaitez obtenir ces indicateurs"
          >
            <MultiSelectField
              label="Périodes à afficher pour les indicateurs d'évaluation"
              options={options}
              renderOption={(p) => ({ label: p })}
              keyExtractor={(p) => p}
              value={projectsPeriods}
              onChange={setProjectsPeriods}
            />
          </WithHelp>
          <Spacer />
          <TextField
            label="Titre du rapport"
            value={title}
            onChange={setTitle}
          />
          <Spacer />
          <Buttons>
            <Button submit label="Générer le rapport" />
          </Buttons>
        </FieldsForm>
      )}
    </Page>
  );
}

type YearlyReportExportProps = {
  title: string;
  overallPeriods: Array<string>;
  tagsPeriod: string | null;
  projectsPeriods: Array<string>;
  overall: OverallStatistics | null;
  tags: TagsPerformance | null;
  projects: ProjectsPerformance | null;
  onClose: () => void;
};

function YearlyReportExport(props: YearlyReportExportProps) {
  const {
    title,
    overallPeriods,
    tagsPeriod,
    projectsPeriods,
    overall,
    tags,
    projects,
  } = props;
  return (
    <Printed>
      <PrintPreviewModal
        width="29.7cm"
        margin="1cm"
        visible={true}
        onClose={props.onClose}
      >
        <Print.WithLogo logo="/logo-cg.png">
          <h1>{title}</h1>
          <p>{`En date du ${dayjs().format("LL")}`}</p>
        </Print.WithLogo>
        <OverallStatisticsTable overall={overall} periods={overallPeriods} />
        <TagsTable tags={tags} period={tagsPeriod} />
        <ProjectsTable projects={projects} periods={projectsPeriods} />
      </PrintPreviewModal>
    </Printed>
  );
}

type OverallStatisticsTableProps = {
  overall: OverallStatistics | null;
  periods: Array<string>;
};

function OverallStatisticsTable(props: OverallStatisticsTableProps) {
  const { periods, overall } = props;
  if (overall === null) return null;

  return (
    <Fragment>
      <h2>Statistiques générales</h2>
      {periods.length === 1
        ? `Statistiques générales du programme HPO concernent la période ${periods[0]}`
        : `Statistiques générales du programme HPO concernent les périodes ${smoothJoin(periods)}`}
      <table>
        <thead>
          <tr>
            <th>Appel à projet</th>
            <th>Structures financées</th>
            <th>Projets financées</th>
            <th>Montants conventionnés</th>
            <th>Montants versés</th>
            <th>Taux de consommation</th>
          </tr>
        </thead>
        <tbody>
          {ProgramKeyValues.map((key) => {
            const item = overall.byProgram[key];
            const program = Programs[key];
            return (
              <tr>
                <td>
                  {program.short}
                  <br />
                  <small>{program.label}</small>
                </td>
                <td>{item.targetedOrganizations}</td>
                <td>{item.targetedProjects}</td>
                <td>{Units.euro.display(item.grantedAmount)}</td>
                <td>{Units.euro.display(item.certifiedAmount)}</td>
                <td>{Units.percentage.display(item.amountsRatio, "-")}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Tous appels à projets confondus</th>
            <th>{overall.all.targetedOrganizations}</th>
            <th>{overall.all.targetedProjects}</th>
            <th>{Units.euro.display(overall.all.grantedAmount)}</th>
            <th>{Units.euro.display(overall.all.certifiedAmount)}</th>
            <th>{Units.percentage.display(overall.all.amountsRatio, "-")}</th>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  );
}

type TagsTableProps = {
  period: string | null;
  tags: TagsPerformance | null;
};

function TagsTable(props: TagsTableProps) {
  const { period, tags } = props;
  if (tags === null) return null;
  return (
    <Fragment>
      <h2>Indicateurs d'impact</h2>
      {`Indicateurs d'impacts pour la période ${period} et synthèse pour chaque appel à projet`}
      {tags.map((tag) => {
        return (
          <Fragment key={tag.id}>
            <h3>{tag.label}</h3>
            <table>
              <thead>
                <tr>
                  <th>Indicateur, projet et structure</th>
                  <th>Réalisé</th>
                  <th>Objectif</th>
                  <th>Ratio</th>
                </tr>
              </thead>
              <tbody>
                {tag.indicators.map((indicator) => {
                  const {
                    id,
                    label,
                    project,
                    organization,
                    performance,
                    unit,
                  } = indicator;
                  const { actual, goal, ratio } = performance;
                  return (
                    <tr key={id}>
                      <th>
                        {label}
                        <br />
                        <small>{project.label}</small>
                        <br />
                        <small>{organization.label}</small>
                      </th>
                      <td>{Units[unit].display(actual)}</td>
                      <td>{Units[unit].display(goal)}</td>
                      <td>{Units.percentage.display(ratio)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>{Units[tag.unit].display(tag.performance.actual)}</th>
                  <th>{Units[tag.unit].display(tag.performance.goal)}</th>
                  <th>{Units.percentage.display(tag.performance.ratio)}</th>
                </tr>
              </tfoot>
            </table>
          </Fragment>
        );
      })}
      <h3>Synthèse par appel à projet</h3>
      <table>
        <thead>
          <tr>
            <th></th>
            {tags.map((t) => (
              <th key={t.id}>{t.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ProgramKeyValues.map((key) => {
            return (
              <tr key={key}>
                <td>{Programs[key].short}</td>
                {tags.map((t) => {
                  const performance = t.programs[key];
                  return (
                    <td key={t.id}>
                      {displayPerformance(performance, t.unit)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            {tags.map((t) => (
              <th key={t.id}>{displayPerformance(t.performance, t.unit)}</th>
            ))}
          </tr>
        </tfoot>
      </table>
    </Fragment>
  );
}

type ProjectsTableProps = {
  periods: Array<string>;
  projects: ProjectsPerformance | null;
};

function ProjectsTable(props: ProjectsTableProps) {
  const { periods, projects } = props;
  if (projects === null) return null;
  return (
    <Fragment>
      <h2>Indicateurs d'évaluation</h2>
      {`Indicateurs d'évalution pour ${periods.length === 1 ? periods[0] : smoothJoin(periods)}, par projet`}
      {projects.map((project) => {
        return (
          <Fragment key={project.id}>
            <h3>{project.label}</h3>
            <p>Par {project.organization}</p>
            <table>
              <thead>
                <tr>
                  <th></th>
                  {periods.map((p) => (
                    <th key={p}>{p}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {project.indicators.map((indicator) => {
                  const { id, label, unit, type } = indicator;
                  return (
                    <tr key={id}>
                      <th>
                        {label}
                        <br />
                        <small>{IndicatorTypeLabels[type]}</small>
                      </th>
                      {periods.map((p) => {
                        const performance = indicator.periods[p];
                        return (
                          <td key={p}>
                            {displayPerformance(performance, unit)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

function displayPerformance(statement: Performance, unit: UnitName) {
  const { actual, goal, ratio } = statement;
  let output: string = "";
  if (actual === null) {
    if (goal === null) {
      output = "-";
    } else {
      output = `- / ${Units[unit].display(goal)}`;
    }
  } else {
    if (goal === null) {
      output = Units[unit].display(actual);
    } else {
      output = `${Units[unit].display(actual)} / ${Units[unit].display(goal)}`;
    }
  }
  if (ratio !== null) {
    output += ` (${Units.percentage.display(ratio)})`;
  }
  return output;
}
