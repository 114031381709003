import React, { CSSProperties, forwardRef } from "react";
import { css } from "@emotion/react";
import TextareaAutosize from "react-textarea-autosize";
import Theme from "../../Theme/client";
import { cssPerStyle } from "@hpo/client/components/text/Text";

const inputCss = css(
  {
    border: "none",
    background: "transparent",
    width: "100%",
    margin: 0,
    padding: 0,
    display: "block",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 12,
    paddingRight: 12,
    color: Theme.color,
    resize: "none",
    "&:focus": {
      outline: "none",
    },
  },
  cssPerStyle.body,
);

type FieldInputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  | "value"
  | "onChange"
  | "readOnly"
  | "placeholder"
  | "onFocus"
  | "onBlur"
  | "id"
  | "disabled"
  | "type"
  | "maxLength"
> & { cursor?: CSSProperties["cursor"] };

const FieldInput = forwardRef<HTMLInputElement, FieldInputProps>(
  function FieldInput(props, ref) {
    const display: FieldInputDisplay = props.disabled
      ? "disabled"
      : props.readOnly
        ? "readonly"
        : "normal";

    const style = css(inputCss, {
      color: colorPerDisplay[display],
      cursor: cursorPerDisplay[display] || props.cursor,
    });

    return (
      <input
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        id={props.id}
        disabled={props.disabled}
        maxLength={props.maxLength}
        type={props.type}
        css={style}
        ref={ref}
      />
    );
  },
);

type FieldTextAreaProps = Pick<
  React.InputHTMLAttributes<FieldTextAreaProps>,
  | "value"
  | "onChange"
  | "readOnly"
  | "placeholder"
  | "onFocus"
  | "onBlur"
  | "id"
  | "disabled"
  | "maxLength"
> & { cursor?: CSSProperties["cursor"] };

export const FieldTextArea = forwardRef<
  HTMLTextAreaElement,
  FieldTextAreaProps
>(function FieldInput(props, ref) {
  const display: FieldInputDisplay = props.disabled
    ? "disabled"
    : props.readOnly
      ? "readonly"
      : "normal";

  const style = css(inputCss, {
    color: colorPerDisplay[display],
    cursor: cursorPerDisplay[display] || props.cursor,
  });
  return (
    <TextareaAutosize
      value={props.value}
      onChange={props.onChange}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      id={props.id}
      disabled={props.disabled}
      maxLength={props.maxLength}
      css={style}
      ref={ref}
    />
  );
});

export default FieldInput;

type FieldInputDisplay = "normal" | "disabled" | "readonly";

const colorPerDisplay: Record<FieldInputDisplay, string> = {
  normal: Theme.color,
  disabled: Theme.text.normal,
  readonly: Theme.text.normal,
};

const cursorPerDisplay: Record<
  FieldInputDisplay,
  CSSProperties["cursor"] | undefined
> = {
  normal: undefined,
  disabled: "not-allowed",
  readonly: "default",
};
