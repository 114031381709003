import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { css } from "@emotion/react";
import Theme from "../Theme/client";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldInput from "./components/FieldInput";
import T from "@hpo/client/components/text/Text";
import Button from "@hpo/client/components/Button";
import Intersperse from "@hpo/client/components/Intersperse";
import Divider from "@hpo/client/components/Divider";

type TextsFieldProps = FieldProps<Array<string> | null> & {
  maxLength?: number;
};

export default function TextsField(props: TextsFieldProps) {
  const {
    value,
    onChange,
    placeholder,
    disabled = false,
    readonly = false,
    required = false,
    maxLength,
    errorMessage,
  } = props;

  const [inputs, setInputs] = useState<Array<string | null>>(props.value || []);

  const id = useFieldId(props.id);
  const validity = useFieldValidity(
    value,
    required,
    () => value === null || value === undefined || value.length === 0,
    (v) => {
      if (inputs.includes(null))
        return "Veuillez remplir tous les lignes, ou retirer les lignes vides";
      if (maxLength && value && value.length > maxLength)
        return `${maxLength} éléments max`;
      if (errorMessage) return errorMessage(v);
      return null;
    },
  );

  const wrapperCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const itemCss = css({
    display: "flex",
  });

  const indexCss = css({
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 80,
    maxWidth: 80,
  });

  const nbOfItems = inputs.length;

  const getItemValue = useCallback(
    (index: number) => {
      const item = inputs[index];
      return item || "";
    },
    [inputs],
  );

  const setItemValue = useCallback(
    (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!onChange) return;
      setInputs((inputs) => {
        const text = e.target.value || null;
        const output = [...inputs];
        output[index] = text;
        return output;
      });
    },
    [],
  );

  const change = useCallback((inputs: Array<string | null>) => {
    if (!onChange) return;
    const withoutNulls = inputs.filter((i) => i !== null) as Array<string>;
    const result = withoutNulls.length === 0 ? null : withoutNulls;
    onChange(result);
  }, []);

  const dropItem = useCallback(
    (index: number) => {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
      change(newInputs);
    },
    [inputs, change],
  );

  const onBlur = useCallback(() => {
    const newInputs = inputs.filter((i) => i !== null) as Array<string>;
    setInputs(newInputs);
    change(newInputs);
  }, [change, inputs]);

  useEffect(() => {
    console.log("INPUT VALUE", value);
    setInputs(value || []);
  }, [value]);

  const nodes: Array<ReactNode> = [];
  for (let i = 0; i < nbOfItems; i++) {
    nodes.push(
      <div css={itemCss} key={i}>
        <div css={indexCss}>
          <T style="minor" color={Theme.color}>{`#${i + 1}`}</T>
        </div>
        <Divider vertical />
        <FieldInput
          type={"text"}
          value={getItemValue(i)}
          onChange={(e) => setItemValue(i, e)}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readonly}
          maxLength={props.maxLength}
          onBlur={onBlur}
        />
        <Button icon="close" style="discreet" onClick={() => dropItem(i)} />
      </div>,
    );
  }

  nodes.push(
    <div css={itemCss} key={nbOfItems}>
      <div css={indexCss}>
        <T style="minor" color={Theme.color}></T>
      </div>
      <Divider vertical />
      <FieldInput
        type={"text"}
        value={getItemValue(nbOfItems + 1)}
        onChange={(e) => setItemValue(nbOfItems, e)}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        maxLength={props.maxLength}
        onBlur={onBlur}
      />
    </div>,
  );

  return (
    <FieldWrapper {...props} id={id} validity={validity}>
      <div css={wrapperCss}>
        <Intersperse between={() => <Divider />}>{nodes}</Intersperse>
      </div>
    </FieldWrapper>
  );
}
