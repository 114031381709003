import { css } from "@emotion/react";
import { useMemo } from "react";
import dayjs from "dayjs";
import { useWhoAmI } from "../services/WhoAmI";
import { Message } from "@hpo/client/models/Message";
import Text from "@hpo/client/components/text/Text";
import Theme from "@hpo/client/utilities/Theme/client";
import AuthorRole from "@hpo/client/utilities/enums/AuthorRole";

type ChatMessageProps = {
  message: Message;
  currentRole: AuthorRole;
};

export default function ChatMessage(props: ChatMessageProps) {
  const { currentRole, message } = props;
  const { content, date, authorLabel, role } = message;
  const whoAmI = useWhoAmI();
  const me = whoAmI.useUser();

  const isMessageOnLeftSide = useMemo(() => {
    return currentRole === role;
  }, [currentRole, role]);

  const wrapperCss = css({
    alignSelf: isMessageOnLeftSide ? "flex-start" : "flex-end",
    width: "80%",
  });

  const messageCss = css({
    border: "1px solid",
    borderColor: isMessageOnLeftSide ? "lightgrey" : Theme.color,
    backgroundColor: isMessageOnLeftSide ? "lightgrey" : Theme.color,
    color: isMessageOnLeftSide ? "black" : "white",
    borderRadius: 5,
    padding: 10,
    textAlign: "justify",
  });

  const infos = useMemo(() => {
    const dateLabel = dayjs(date).format("DD/MM/YYYY HH:mm");
    return dateLabel + " - " + authorLabel;
  }, [date, authorLabel, me]);

  return (
    <div css={wrapperCss}>
      <div css={messageCss}>
        <Text style="minor">{content}</Text>
      </div>
      <Text style="minor" color="grey" opacity={0.7}>
        {infos}
      </Text>
    </div>
  );
}
