import { object, string, Infer, array, boolean, nullable } from "superstruct";
import { emailCoercer } from "../utilities/models/Email";
import { RoleSchema } from "../utilities/enums/Role";
import { isoDateSchema } from "../utilities/models/Date";
import { OrganizationSummarySchema } from "./Organization";

// User

export const UserSchema = object({
  id: string(),
  email: emailCoercer,
  firstName: string(),
  lastName: string(),
});

export type User = Infer<typeof UserSchema>;

// UserCreation

export const UserCreationSchema = object({
  email: emailCoercer,
  firstName: string(),
  lastName: string(),
  organization: nullable(string()),
  roles: array(RoleSchema),
});

export type UserCreation = Infer<typeof UserCreationSchema>;

// UserUpdate

export const UserUpdateSchema = object({
  firstName: string(),
  lastName: string(),
  organization: nullable(string()),
  roles: array(RoleSchema),
});

export type UserUpdate = Infer<typeof UserUpdateSchema>;

// UserAccess

export const UserAccessSchema = object({
  id: string(),
  email: emailCoercer,
  firstName: string(),
  lastName: string(),
  roles: array(RoleSchema),
  hasPassword: boolean(),
  passwordExpiration: nullable(isoDateSchema),
  organization: nullable(OrganizationSummarySchema),
});

export type UserAccess = Infer<typeof UserAccessSchema>;
