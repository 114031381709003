import { Infer, enums } from "superstruct";

export const BeneficiaryPaymentStatusValues = [
  "locked", // Paiement à venir (le bénéficiaire ne peut pas encore fournir les documents)
  "available", // Paiement disponible à la saisie (en attente de docs du bénéficiaire) ==> En théorie, il n'y en a qu'1 seul à l'instant T (prévoir X, mais pas 8000 non plus)
  "under-analyse", // Paiement en cours d'analyse, décision à venir
  "ended", // Paiement terminé (virement émi)
] as const;

export const BeneficiaryPaymentStatusSchema = enums(
  BeneficiaryPaymentStatusValues,
);

export type BeneficiaryPaymentStatus = Infer<
  typeof BeneficiaryPaymentStatusSchema
>;
