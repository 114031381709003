import { useState } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import { ServiceBeneficiary } from "@hpo/client/models/Expense";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import DateField from "@hpo/client/utilities/fields/DateField";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import Toasting from "@hpo/client/components/Toasting";
import ExpenseType from "@hpo/client/utilities/enums/ExpenseType";
import { ProjectDraft } from "@hpo/client/models/Project";

type ServiceSideBarProps = {
  project: ProjectDraft;
  service: ServiceBeneficiary | null;
  visible: boolean;
  onDone: (newService: string | null) => unknown;
};

/** @todo : voir navigators/MyProjectsNavigator/MyProjectScreen/MyProjectActions/ActionSideBar.tsx */
export default function ServiceSideBar(props: ServiceSideBarProps) {
  const { project, service, visible, onDone } = props;
  const server = useServerSdk();

  const [label, setLabel] = useState<string | null>(service && service.label);

  const [provider, setProvider] = useState<string | null>(
    service && service.provider,
  );

  const [quotationReference, setQuotationReference] = useState<string | null>(
    service && service.quotationReference,
  );

  const [quotationDate, setQuotationDate] = useState<string | null>(
    service && service.quotationDate,
  );

  const [quotationDetail, setQuotationDetail] = useState<string | null>(
    service && service.quotationDetail,
  );

  const [annualCost, setAnnualCost] = useState<number | null>(
    service && service.annualCost,
  );

  const [saveService, savingStatus] = useSubmitCallback(async () => {
    if (
      !label ||
      !provider ||
      !quotationReference ||
      !quotationDate ||
      !quotationDetail ||
      annualCost === null
    ) {
      throw new MessageException("Veuillez remplir tous les champs", null);
    }

    if (service) {
      await server.updateExpense(project.id, service.id, {
        label,
        type: ExpenseType.Service,
        provider,
        quotationReference,
        quotationDate,
        quotationDetail,
        annualCost,
      });
      Toasting.success("Succès", "Dépense mise à jour");
      onDone(service.id);
    } else {
      const expense = await server.createExpense(project.id, {
        label,
        type: ExpenseType.Service,
        provider,
        quotationReference,
        quotationDate,
        quotationDetail,
        annualCost,
      });
      Toasting.success("Succès", "Dépense créée");
      onDone(expense);
    }
  }, [
    service,
    label,
    provider,
    quotationReference,
    quotationDate,
    quotationDetail,
    annualCost,
  ]);

  return (
    <SideBar
      visible={visible}
      onHide={() => onDone(null)}
      header={
        <TextTitleMinor>
          {service ? service.label : "Nouveau devis"}
        </TextTitleMinor>
      }
    >
      <ErrorToast error={savingStatus.error} />
      <TextField
        required
        id="label"
        label="Intitulé"
        value={label}
        onChange={setLabel}
      />
      <Spacer />
      <TextField
        required
        id="provider"
        label="Fournisseur"
        value={provider}
        onChange={setProvider}
      />
      <Spacer />
      <TextField
        required
        id="quotationReference"
        label="N° de devis"
        value={quotationReference}
        onChange={setQuotationReference}
      />
      <Spacer />
      <DateField
        required
        id="quotationDate"
        label="Date du devis"
        value={quotationDate}
        onChange={setQuotationDate}
      />
      <Spacer />
      <TextField
        required
        id="quotationDetail"
        label="Objets de la dépense (détail des lignes du devis)"
        value={quotationDetail}
        onChange={setQuotationDetail}
      />
      <Spacer />
      <NumberField
        id="annualCost"
        value={annualCost}
        onChange={setAnnualCost}
        label="Coût annuel"
        unit="euro"
        allowZero
        required
      />
      <Spacer scale={2} />
      <Button
        onClick={saveService}
        label="Valider"
        loading={savingStatus.running}
      />
    </SideBar>
  );
}
