import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import Toasting from "@hpo/client/components/Toasting";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import Await from "@hpo/client/utilities/Await";
import List, { ListItem } from "@hpo/client/utilities/List";
import ProjectStatusBadge from "@hpo/client/utilities/ProjectStatusBadge";
import Spacer from "@hpo/client/utilities/Spacer";
import ProjectStatus from "@hpo/client/utilities/enums/ProjectStatus";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { myProjectRoute, newProjectRoute } from "@hpo/client/utilities/routes";
import useData from "@hpo/client/utilities/useData";

export default function MyProjectsScreen() {
  const server = useServerSdk();
  const [projects] = useData(async () => server.getMyProjects(), []);

  return (
    <ContentLayout
      title="Mes projets"
      actions={[
        <Button
          key="new"
          label="Nouveau projet"
          to={newProjectRoute.getRootUrl()}
          icon="plus"
        />,
      ]}
    >
      <Await promise={projects}>
        {(projects) => {
          return (
            <List
              data={projects}
              renderItem={(project) => (
                <ListItem
                  label={getProjectLabel(project)}
                  help={project.organization.businessName}
                  right={<ProjectStatusBadge status={project.status} />}
                  to={
                    project.status === ProjectStatus.Draft
                      ? myProjectRoute.getRootUrl({ projectId: project.id })
                      : undefined
                  }
                  onClick={
                    project.status !== ProjectStatus.Draft
                      ? () => {
                          Toasting.error(
                            "Accès limité",
                            project.status === ProjectStatus.UnderInstruction
                              ? "Votre projet est en cours d'instruction"
                              : "Les informations de votre projet seront prochainement accessibles",
                          );
                        }
                      : undefined
                  }
                />
              )}
            />
          );
        }}
      </Await>
      <Spacer />
    </ContentLayout>
  );
}
