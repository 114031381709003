import ProgramKey from "../enums/ProgramKey";

export type ComplianceDescriptor = {
  key: string;
  projectType: ProgramKey;
  label: string;
};

export function getCompliancesByProjectType(projectType: ProgramKey) {
  return Compliances.filter((a) => a.projectType === projectType);
}

const animationCompliances: ComplianceDescriptor[] = [
  {
    key: "animation_2axes_deux_acteurs",
    projectType: ProgramKey.Animation,
    label:
      "Pour les 2 axes : Le porteur de projet atteste être une structure mettant en œuvre des formes d’animations territoriales associant au moins deux acteurs, qu’ils opèrent ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole.",
  },
  {
    key: "animation_2axes_cooperation",
    projectType: ProgramKey.Animation,
    label:
      "Pour les deux axes : Le porteur de projet atteste être une structure mettant en place de nouvelles formes de coopération entre partenaires, ou développant des formes de coopération existantes, si elles démarrent une nouvelle activité.",
  },
  {
    key: "animation_axe1_structure_type",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : Le porteur de projet atteste être une structure agricole collective privée (petites et moyennes entreprises (PME) et associations assimilées)",
  },
  {
    key: "animation_axe1_active",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : Le porteur de projet atteste être une structure active dans le secteur agricole et dans une des filières identitaires et émergentes ou fortement concurrencées par les importations ou pour des territoires identitaires et émergents définis dans le règlement de consultation",
  },
  {
    key: "animation_axe1_actions_animation",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : le porteur de projet atteste être une structure réalisant des actions d’animation territoriale au bénéfice des agriculteurs des filières identitaires et émergentes ou fortement concurrencées par les produits agricoles d’importation, ou pour des territoires identitaires et émergents",
  },
  {
    key: "animation_axe1_programme_actions",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : Le porteur de projet atteste être une structure ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département",
  },
  {
    key: "animation_axe1_cooperation",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : le porteur de projet atteste être une structure ayant mis en place, pour la réalisation du programme d’actions, obligatoirement une coopération, contractualisée par une convention réunissant au moins 3 autres acteurs, représentant différents maillons de la filière et opérant ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole",
  },
  {
    key: "animation_axe1_production_locale",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : Le porteur de projet atteste être une structure représentant, en collectif, avec les partenaires associés, à minima 50% de la production locale du territoire des produits agricoles visés des filières identitaires et émergentes ou fortement concurrencées par les produits agricoles d’importation ",
  },
  {
    key: "animation_axe1_FEADER",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : Le porteur de projet atteste être une structure n’ayant pas déjà été attributaires d’une aide financière du FEADER TO 77, du FEADER TO 78 ou d’autres fonds publics, pour financer les dépenses proposées au présent dispositif.",
  },
  {
    key: "animation_axe2_amenagement",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Le porteur de projet atteste être une structure oeuvrant à l’aménagement et le développement des territoires de La Réunion, et accompagnant les projets au bénéfice du secteur agricole et encourageant l’innovation, notamment en milieu rural",
  },
  {
    key: "animation_axe2_active",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Le porteur de projet atteste être une structure active dans le secteur agricole, représentative de l’ensemble des acteurs du territoire (des opérateurs économiques dont agriculteurs, artisans, des associations locales, des organismes contribuant au développement territorial, etc…).",
  },
  {
    key: "animation_axe2_benefices",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Le porteur de projet atteste être une structure réalisant des actions d’animation territoriale bénéficiant au secteur agricole, permettant par exemple : la création et le développement de projets alimentaires territoriaux (PAT), la mise en place de projets de protection et de mise en valeur des espaces agricoles naturels péri-urbains (PAEN), la mise en œuvre de chartes agricoles, etc...",
  },
  {
    key: "animation_axe2_AGRIPEI",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Le porteur de projet atteste être une structure ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département.",
  },
  {
    key: "animation_axe2_cooperation",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Le porteur de projet atteste être une structure ayant mis en place, pour la réalisation du programme d’actions, obligatoirement une coopération, contractualisée par une convention réunissant au moins une collectivité intercommunale et au moins un autre acteur opérant ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole.",
  },
  {
    key: "animation_axe2_programme_actions",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Le porteur de projet atteste être une structure proposant un programme d’actions, soit à l’échelle du territoire de La Réunion, soit à l’échelle d’un des 5 bassins géographiques de La Réunion.",
  },
  {
    key: "animation_axe2_aide_financiere",
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Le porteur de projet atteste être une structure n’ayant pas déjà été attributaires d’une aide financière d’autres fonds publics, pour financer les dépenses proposées au présent dispositif.",
  },
];

const animationChambreCompliances: ComplianceDescriptor[] = [
  {
    key: "animation_chambre_structure",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure mettant en œuvre des formes d’animations territoriales associant au moins deux acteurs, qu’ils opèrent ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole.",
  },
  {
    key: "animation_chambre_cooperation",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure mettant en place de nouvelles formes de coopération entre partenaires, ou développant des formes de coopération existantes, si elles démarrent une nouvelle activité.",
  },
  {
    key: "animation_chambre_actions_animation",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure réalisant des actions d’animation dans le secteur agricole.",
  },
  {
    key: "animation_chambre_developpement_economique",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure favorisant le développement économique du territoire (nouveaux débouchés ou débouchés mieux valorisés pour l’agriculture, création d’emplois, etc.) .",
  },
  {
    key: "animation_chambre_nouveaux_modes_fabrication",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure privilégiant de nouveaux modes de fabrication et des itinéraires techniques respectueux de l’environnement et permettant d’aller au-delà des exigences réglementaires.",
  },
  {
    key: "animation_chambre_consommation_alimentaire",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure favorisant de nouveaux modes de consommation alimentaire, ou facilitant la succession de l’exploitation, en particulier pour le renouvellement des générations au niveau de l’exploitation.",
  },
  {
    key: "animation_chambre_realisation_objectifs",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure contribuant à la réalisation des objectifs et des priorités de la politique de développement rural.",
  },
  {
    key: "animation_chambre_creation_poles",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure aboutissant à la création de pôles et de réseaux.",
  },
  {
    key: "animation_chambre_AGRIPEI",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département",
  },
  {
    key: "animation_chambre_cooperation",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure ayant mis en place, pour la réalisation du programme d’actions, obligatoirement une coopération avec au moins un autre acteur opérant ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole.",
  },
  {
    key: "animation_chambre_FEADER",
    projectType: ProgramKey.AnimationChambre,
    label:
      "Le porteur de projet atteste être une structure n’ayant pas déjà été attributaires d’une aide financière du FEADER TO 77, du FEADER TO 78 ou d’autres fonds publics, pour financer les dépenses proposées au présent dispositif.",
  },
];

const echangesConnaissancesCompliances: ComplianceDescriptor[] = [
  {
    key: "echange_connaissances_structure",
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Le porteur de projet atteste être une structure agricoles, en faveur d'un collectif d'agriculteurs de La Réunion, de type petites et moyennes entreprises (PME) et associations assimilées, actives dans les secteurs de la production agricole primaire, de la transformation et la commercialisation de produits.",
  },
  {
    key: "echange_connaissances_echanges",
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Le porteur de projet atteste être une structure agricoles, en faveur d'un collectif d'agriculteurs de La Réunion, réalisant des échanges de connaissances et d'informations techniques au bénéfice des agriculteurs pour les filières identitaires et émergentes.",
  },
  {
    key: "echange_connaissances_programme_actions",
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Le porteur de projet atteste être une structure agricoles, en faveur d'un collectif d'agriculteurs de La Réunion, ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département.",
  },
  {
    key: "echange_connaissances_partenariats",
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Le porteur de projet atteste être une structure agricoles, en faveur d'un collectif d'agriculteurs de La Réunion, ayant mis en place obligatoirement des partenariats avec au moins une autre structure pour la réalisation du programme d’actions.",
  },
  {
    key: "echange_connaissances_FEADER",
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Le porteur de projet atteste être une structure agricoles, en faveur d'un collectif d'agriculteurs de La Réunion, n’ayant pas déjà été attributaires d’une aide financière du FEADER TO 77, du FEADER TO 78 ou d’autres fonds publics, pour financer les dépenses proposées au présent dispositif.",
  },
  {
    key: "echange_connaissances_salaries",
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Le porteur de projet atteste être une structure agricoles, en faveur d'un collectif d'agriculteurs de La Réunion, ayant moins de 10 salariés et représentatives de filières identitaires et émergentes de La Réunion..",
  },
];

const lutteCollectiveCompliances: ComplianceDescriptor[] = [
  {
    key: "lutte_collective_competences",
    projectType: ProgramKey.LutteCollective,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant les compétences internes nécessaires pour s’assurer que les aides perçues ne concernent uniquement les maladies animales, les organismes nuisibles aux végétaux ou les espèces exotiques envahissantes pour lesquels il existe, à l'échelle de l'Union ou au niveau national, des dispositions législatives, réglementaires ou administratives précisées dans le régime cadre national SA.108469",
  },
  {
    key: "lutte_collective_programme_actions",
    projectType: ProgramKey.LutteCollective,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département.",
  },
  {
    key: "lutte_collective_partenariats",
    projectType: ProgramKey.LutteCollective,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant mis en place obligatoirement des partenariats avec au moins une autre structure pour la réalisation du programme d’actions, y compris dans le cadre de consortiums associant plusieurs partenaires.",
  },
  {
    key: "lutte_collective_plan_lutte",
    projectType: ProgramKey.LutteCollective,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant mis en place un plan de lutte collectif, préalablement validé en CROPSAV.",
  },
  {
    key: "lutte_collective_attributaires",
    projectType: ProgramKey.LutteCollective,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, n’ayant pas déjà été attributaires d’une aide financière du FEADER TO 77, du FEADER TO 78 ou d’autres fonds publics, pour financer les dépenses proposées au présent dispositif..",
  },
];

const lutteCollectiveAgroEcoCompliances: ComplianceDescriptor[] = [
  {
    key: "lutte_collective_agroeco_AGRIPEI",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département et privilégiant de nouveaux modes de fabrication et des solutions innovantes, plus respectueux de l'environnement",
  },
  {
    key: "lutte_collective_agroeco_cooperation",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant mis en place obligatoirement une coopération avec au moins un autre acteur, opérant ou non dans le secteur agricole dont la coopération est avantageuse principalement pour le secteur agricole.",
  },
  {
    key: "lutte_collective_agroeco_CROPSAV",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant mis en place un plan de lutte collectif, préalablement validé en CROPSAV.",
  },
  {
    key: "lutte_collective_agroeco_PBI",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, ayant mis en place un plan de promotion de la protection biologique intégrée (PBI) et du tranfert à destination des agriculteurs.",
  },
  {
    key: "lutte_collective_agroeco_FEADER",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, n’ayant pas déjà été attributaires d’une aide financière du FEADER TO 77, du FEADER TO 78 ou d’autres fonds publics, pour financer les dépenses proposées au présent dispositif.",
  },
  {
    key: "lutte_collective_agroeco_cooperation",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, mettant en œuvre des formes de coopération associant au moins deux acteurs, qu’ils opèrent ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole.",
  },
  {
    key: "lutte_collective_agroeco_nouvelle_cooperation",
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le porteur de projet atteste être un organisme à vocation sanitaire (OVS), ou un consortium de partenaires incluant au moins un OVS, au service des agriculteurs de La Réunion, mettant en place de nouvelles formes de coopération entre partenaires, ou développant des formes de coopération existantes, si elles démarrent une nouvelle activité.",
  },
];

const promotionCompliances: ComplianceDescriptor[] = [
  {
    key: "promotion_structure",
    projectType: ProgramKey.PromotionProduits,
    label:
      "Le porteur de projet atteste être une petite ou moyenne entreprise (PME) ou associations assimilées, actives dans les secteurs de la production agricole primaire, de la transformation et la commercialisation de produits agricoles des filières identitaires et émergentes",
  },
  {
    key: "promotion_actions",
    projectType: ProgramKey.PromotionProduits,
    label:
      "Le porteur de projet atteste être une structure réalisant des actions de communication ou de promotion en faveur des produits agricoles réunionnais, soit des publications destinées à mieux faire connaître les produits agricoles auprès du grand public ou à la diffusion des connaissances scientifiques et des informations factuelles sur les systèmes de qualité et les produits agricoles génériques et leurs bienfaits nutritionnels.",
  },
  {
    key: "promotion_programme_actions",
    projectType: ProgramKey.PromotionProduits,
    label:
      "Le porteur de projet atteste être une structure ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département.",
  },
  {
    key: "promotion_partenariats",
    projectType: ProgramKey.PromotionProduits,
    label:
      "Le porteur de projet atteste être une structure ayant mis en place obligatoirement des partenariats avec au moins une autre structure pour la réalisation du programme d’actions.",
  },
  {
    key: "promotion_production_locale",
    projectType: ProgramKey.PromotionProduits,
    label:
      "Le porteur de projet atteste être une structure représentant, en collectif, avec les partenaires associés, à minima 50% de la production locale des produits agricoles visés par l’action de promotion.",
  },
  {
    key: "promotion_FEADER",
    projectType: ProgramKey.PromotionProduits,
    label:
      "Le porteur de projet atteste être une structure n’ayant pas déjà été attributaires d’une aide financière du FEADER TO 77, du FEADER TO 78 ou d’autres fonds publics, pour financer les dépenses proposées au présent dispositif.",
  },
];

const systemesQualitéCompliances: ComplianceDescriptor[] = [
  {
    key: "systemes_active",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le porteur de projet atteste être une structure qui n'est pas active dans la production primaire de produits agricoles.",
  },
  {
    key: "systemes_minimis",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Ou qui excerce ses activités à la fois dans le secteur de la production primaire de produits agricoles, et dans d'autres secteurs, ou lorsqu'elle excerce d'autres activités, entrant dans le champ d'application du règlement 2023/2831, sous réserve qu'il y a une veille, par des moyens appropriés, tels que la séparation des activités ou de la comptabilité, à ce que les activités excercées dans les secteurs exclus ne bénéficient pas des aides de minimis.",
  },
  {
    key: "systemes_minimis",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le porteur de projet atteste être une structure dont le montant total des aides de minimis ayant été obtenues, additionnées à celles demandées, n'excède pas 300 000 € sur une préiode de trois années glissantes",
  },
  {
    key: "systemes_status",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le porteur de projet atteste être une structure dont les statuts indiquent leur qualité d'organismes de gestion au sens des règlements régissant les systèmes de qualité.",
  },
  {
    key: "systemes_systemes_qualite",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le porteur de projet atteste être une structure permettant à des producteurs de produits agricoles de participer à des systèmes de qualité, règlementaires ou volontaires.",
  },
  {
    key: "systemes_AGRIPEI",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le porteur de projet atteste être une structure ayant un programme d’actions proposé répondant aux objectifs du Plan AGRIPEI 2030 du Département.",
  },
  {
    key: "systemes_partenariats",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le porteur de projet atteste être une structure ayant mis en place obligatoirement des partenariats avec au moins une autre structure pour la réalisation du programme d’actions.",
  },
  {
    key: "systemes_FEADER",
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le porteur de projet atteste être une structure n’ayant pas déjà été attributaires d’une aide financière du FEADER TO 77, du FEADER TO 78 ou d’autres fonds publics, pour financer les dépenses proposées au présent dispositif.",
  },
];

const Compliances: ComplianceDescriptor[] = [
  ...animationCompliances,
  ...animationChambreCompliances,
  ...echangesConnaissancesCompliances,
  ...lutteCollectiveCompliances,
  ...lutteCollectiveAgroEcoCompliances,
  ...promotionCompliances,
  ...systemesQualitéCompliances,
];

export default Compliances;
