import { rgba } from "polished";

export type AppTheme = ReturnType<typeof buildTheme>;

export function buildTheme(color: string) {
  return {
    color: color,
    warning: "#c89400",
    error: "#8c0921",
    text: {
      normal: rgba("black", 0.8),
      fade: rgba("black", 0.5),
    },
    background: {
      loggedin: "#ECECEC",
      anonymous: "white",
    },
  };
}
