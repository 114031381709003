import { Fragment, useMemo, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { useMe } from "../../RootNavigator/services/WhoAmI";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import UserSideBar from "./UserSideBar";
import { UserAccess } from "@hpo/client/models/User";
import {
  getRoleLabel,
  getUserName,
} from "@hpo/client/utilities/helpers/UserHelper";
import Role, { RoleValues } from "@hpo/client/utilities/enums/Role";
import Sections from "@hpo/client/utilities/Sections";
import Button from "@hpo/client/components/Button";
import List, { ListItem } from "@hpo/client/utilities/List";
import Theme from "@hpo/client/utilities/Theme/client";
import Badge from "@hpo/client/utilities/Badge";
import { Organization } from "@hpo/client/models/Organization";
import Spacer from "@hpo/client/utilities/Spacer";
import PageHeader from "@hpo/client/utilities/PageHeader";

type UsersViewProps = {
  users: Array<UserAccess>;
  organizations: Array<Organization>;
  onReload: () => void;
};

export default function UsersView(props: UsersViewProps) {
  const { users, organizations, onReload } = props;

  const server = useServerSdk();
  const me = useMe();

  const groupsPerRole = useMemo(() => {
    const roles: Array<Role> = [...RoleValues];
    return roles.map((r) => ({
      role: r,
      users: users.filter((u) => u.roles.includes(r)),
    }));
  }, [users]);

  const noRolesUsers = useMemo(() => {
    return users.filter((u) => u.roles.length === 0);
  }, [users]);

  const [openUser, setOpenUser] = useState<string | "new" | null>(null);

  const onUserDone = async (id: string | null) => {
    if (openUser === me.id) {
      await server.refreshLogin();
    }
    setOpenUser(id);
    onReload();
  };

  return (
    <Fragment>
      <PageHeader
        title="Utilisateurs"
        titleStyle="subtitle"
        right={
          <Button
            icon="plus"
            label="Nouvel utilisateur"
            onClick={() => setOpenUser("new")}
          />
        }
      />
      <Spacer />
      <Sections>
        {groupsPerRole.map((group, i) => {
          return (
            <Sections.Section
              title={getRoleLabel(group.role)}
              id={group.role}
              opened={i === 0}
              key={group.role}
            >
              <UsersList
                users={group.users}
                onClick={(u) => setOpenUser(u.id)}
              />
            </Sections.Section>
          );
        })}
        {noRolesUsers.length ? (
          <Sections.Section
            title={"Utilisateurs n'ayant aucun rôle"}
            id={"no-role"}
            opened={false}
            key={"no-role"}
          >
            <WithHelp
              title="Attention"
              text="Ces utilisateurs n'ont aucun rôle. Ils ne peuvent accéder à aucune fonctionnalité dans la plateforme"
              color={Theme.error}
            >
              <UsersList
                users={noRolesUsers}
                onClick={(u) => setOpenUser(u.id)}
              />
            </WithHelp>
          </Sections.Section>
        ) : null}
      </Sections>
      {users.map((u) => (
        <UserSideBar
          key={u.id}
          onDone={onUserDone}
          visible={u.id === openUser}
          user={u}
          organizations={organizations}
        />
      ))}
      <UserSideBar
        onDone={onUserDone}
        visible={openUser === "new"}
        user={null}
        organizations={organizations}
      />
    </Fragment>
  );
}

type UsersListProps = {
  users: Array<UserAccess>;
  onClick: (u: UserAccess) => unknown;
};

function UsersList(props: UsersListProps) {
  const { users, onClick } = props;
  return (
    <List
      data={users}
      renderItem={(d) => {
        const otherRoles = d.roles.length - 1;
        return (
          <ListItem
            label={getUserName(d)}
            help={[
              d.organization ? d.organization.businessName : null,
              d.email,
              otherRoles === 1
                ? "1 autre role"
                : otherRoles > 1
                  ? `${otherRoles} autres rôles`
                  : null,
            ]}
            onClick={() => onClick(d)}
            right={
              d.hasPassword ? null : (
                <Badge color={Theme.error}>Aucun accès à la plateforme</Badge>
              )
            }
          />
        );
      }}
    />
  );
}
