import { rgba } from "polished";
import { parse } from "node-html-parser";
import hash from "object-hash";
import Exception from "../errors/Exception";
import { AppTheme } from "../Theme/utilities";

export function getPrintStyles(theme: AppTheme) {
  return `
    * { padding : 0px ; margin : 0px ; box-sizing : border-box } 
  html, body { font-family : "Futura" ; }
  body {font-size : 0.4cm ; }
  h1 { font-size : 0.8cm ; }
  h2 { font-size : 0.6cm ; margin-top : 1cm }
  h3 { font-size : 0.5cm ; margin-top : 0.5cm }
  th {}
  small { opacity : 0.7 ; }
  table { border : 1px solid black ; border-radius : 0.1cm ; border-spacing : 0px ; width : 100%  }
  th { text-align : left ; color : ${theme.color} ; background-color : ${rgba(theme.color, 0.1)} }
  td, th { padding-top : 0.1cm ; padding-bottom : 0.1cm ; padding-left : 0.2cm ; padding-right : 0.2cm ; }
  td, th { border-right : 1px solid black }
  td:last-child, th:last-child { border-right : none }
  td { border-bottom : 1px solid ${rgba("black", 0.5)} }
  tbody tr:last-child td, tbody tr:last-child th { border-bottom : none }
  thead th { border-bottom : 1px solid ${theme.color} ; border-top : 1px solid ${theme.color} }
  thead:first-child th { border-top : 0px solid ${theme.color} }
  tbody th { border-bottom : 1px solid ${theme.color} }
  tbody tr.total td { background-color : ${rgba(theme.color, 0.1)} ; color : ${theme.color} ; }
  tbody tr.divider td { border-left : none ; border-right : none ; height : 0.5cm }
  tfoot th { border-top : 1px solid ${theme.color} ; border-bottom : 1px solid ${theme.color} }
  tfoot:last-child th { border-bottom : 0px solid ${theme.color} }
  span.badge { text-transform : uppercase ; font-weight : 400 ; display : inline-block ; background-color : ${rgba("black", 0.1)} ; padding-left : 0.1cm ; padding-right : 0.1cm ; padding-top : 0.07cm ; padding-bottom : 0.07cm ; border-radius : 0.1cm ; font-weight : 600 ; font-size : 0.35cm   }
  span.badge.success { background-color : ${theme.color} ; color : white }
  span.badge.warning { background-color : ${theme.warning} ; color : white }
  span.badge.error { background-color : ${theme.error} ; color : white }
  label { color : ${rgba("black", 0.7)} ; text-decoration : underline }
  .columns{ display : grid ; grid-auto-columns: minmax(0, 1fr); grid-auto-flow: column; gap : 0.5cm }
  table, .info, hr, .with-logo, .signature, .analysis { margin-top : 0.25cm }
  body > *:first-child{margin-top : 0cm ;}
  .with-logo{display :flex ; flex-direction : row ; align-items : center}
  .with-logo .content{flex-grow : 1 ;}
  .with-logo .logo{width : 3cm ; margin-left : 1cm}
  .with-logo .logo img{width : 3cm ; height : 3cm}
  hr{ border : none ; border-bottom : 1px solid ${rgba("black", 0.5)}}
  a{ color : ${theme.color}}
  .page-break { break-after : page } 
  section { break-inside : avoid }
  .signature .frame { height : 4cm ; display : flex ; flex-direction : column ; align-items : center ; justify-content : center ; text-align : center ; border : 1px solid black ; border-radius : 0.1cm}
  .analysis { background-color : ${rgba("black", 0.1)} ; padding : 0.3cm ; border-radius : 0.1cm ; display : flex ; flex-direction : row }
  .analysis .comment-zone { flex-grow : 1 ; margin-left : 0.3cm}`;
}

export function getPrintHtml(origin: string, body: string, theme: AppTheme) {
  return `
    <html>
      <head>
        <base href="${origin}" />
        <link href="/futura/stylesheet.css" rel="stylesheet" />
        <style>
          ${getPrintStyles(theme)}
        </style>
      </head>
      <body>${body}</body>
    </html>`;
}

export function getPrintHash(html: string) {
  const root = parse(html);
  const nodes = root.querySelectorAll(".hashed");
  const texts = nodes.map((n) => n.outerHTML);
  if (!texts.length) return null;
  return hash(texts);
}

export class NoContentToHash extends Exception {}
