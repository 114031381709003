export enum AnnexType {
  A2Product = "a2_product",
  A2Service = "a2_service",
  A2Salary = "a2_salary",
  A3 = "a3",
  A4 = "a4",
  A5 = "a5",
}

export const AnnexTypeValues = [
  AnnexType.A2Product,
  AnnexType.A2Service,
  AnnexType.A2Salary,
  AnnexType.A3,
  AnnexType.A4,
  AnnexType.A5,
] as const;

// export const AnnexTypeSchema = enums(AnnexTypeValues);

export default AnnexType;
